import { useEffect, useState, useMemo, useCallback } from "react";
import {
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Card,
  Col,
  CardBody,
} from "reactstrap";
import classnames from "classnames";
import DeleteModal from "../../Components/Common/DeleteModal";
import TableContainer from "../../Components/Common/TableContainer";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import {
  deleteProducts,
  getProducts as onGetProducts,
} from "../../slices/thunks";
import _, { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { countsOfProductStatus, listOfProduct } from "api/productApi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import {
  currencyFormat,
  notFound,
  productPurchase,
} from "utils/helpers/constant";
import LoaderBlur from "Components/Common/BlurLoader";
import { IMAGE_URL } from "api/apiServices";
import { getRole } from "utils/helpers/sessionStores";
import { RolesEnum } from "utils/helpers/enums";

interface Counts {
  allCount: number;
  publishCount: number;
  draftCount: number;
  onlineCount: number;
}

const Product = (props: any) => {
  const dispatch: any = useDispatch();
  const role = getRole();
  const [productList, setProductList] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>("1");
  const [product, setProduct] = useState<any>(null);
  const [productData, setProductData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [countsData, setCountsData] = useState<Counts>();
  const [customPageSize, setCustomPageSize] = useState(10);
  const [searchProductData, setSearchProductData] = useState([]);
  const [columnName, setColumnName] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");

  const handleFetchSorting = (page: number, id: string, order: string) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrder(order);
  };

  const [categoryCounts, setCategoryCounts] = useState({
    all: 0,
    Publish: 0,
    Draft: 0,
    Online: 0,
  });

  useEffect(() => {
    if (productData?.length > 0) {
      const counts = productData.reduce(
        (acc: any, product: any) => {
          acc.all++;
          acc[product.product_status]++;
          return acc;
        },
        { all: 0, Publish: 0, Draft: 0, Online: 0 }
      );
      setCategoryCounts(counts);
    }
  }, [productData]);

  const fetchProductTab = (status: string) => {
    setIsLoading(true);
    let requestData: {
      sortOrder: {
        field: string;
        order: string;
      };
      pageNumber: number;
      pageSize: number;
      product_status?: string;
    } = {
      sortOrder: { field: columnName, order: sortOrder },
      pageNumber: 1,
      pageSize: customPageSize,
    };

    if (status !== "all") {
      requestData.product_status = status;
    }

    listOfProduct(requestData)
      .then(
        (response: {
          data: {
            listOfProductData: any;
            totalRecordsCount: number;
            totalPages: number;
            numberOfRows: number;
          };
        }) => {
          setProductData(response?.data?.listOfProductData);
          setTotalRecords(response.data.totalRecordsCount);
          setTotalPages(response?.data?.totalPages);
          setTotalNumberOfRows(response?.data?.numberOfRows);
        }
      )
      .catch((error: any) => {
        return error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchProductData = (status: string) => {
    setIsLoading(true);
    let requestData: {
      sortOrder: {
        field: string;
        order: string;
      };
      pageNumber: number;
      pageSize: number;
      product_status?: string;
    } = {
      sortOrder: { field: columnName, order: sortOrder },
      pageNumber: currentPage,
      pageSize: customPageSize,
    };

    if (status !== "all") {
      requestData.product_status = status;
    }

    listOfProduct(requestData)
      .then(
        (response: {
          data: {
            listOfProductData: any;
            totalRecordsCount: number;
            totalPages: number;
            numberOfRows: number;
          };
        }) => {
          setProductData(response?.data?.listOfProductData);
          setTotalRecords(response.data.totalRecordsCount);
          setTotalPages(response?.data?.totalPages);
          setTotalNumberOfRows(response?.data?.numberOfRows);
          if (searchValue) {
            fetchSearchData();
          }
        }
      )
      .catch((error: any) => {
        return error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchCountOfProductStatus = () => {
    setIsLoading(true);
    countsOfProductStatus()
      .then((response) => {
        setCountsData(response?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFetchData = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (searchValue) {
      fetchSearchData();
    } else {
      fetchProductData("all");
    }
  }, [currentPage, searchValue, customPageSize, sortOrder, columnName]);

  useEffect(() => {
    if (searchValue === "") {
      setCurrentPage(1);
    }
  }, [searchValue]);

  useEffect(() => {
    setProductList(productData);
  }, [productData]);

  useEffect(() => {
    if (!isEmpty(productData)) setProductList(productData);
  }, [productData]);

  useEffect(() => {
    fetchCountOfProductStatus();
  }, []);

  const toggleTab = (tab: any, type: string) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let status = "";
      if (type !== "all") {
        status = type;
        setSearchStatus(status);
      }

      fetchProductTab(status);
    }
  };

  useEffect(() => {
    const sliderq = document.getElementById("product-price-range");
    sliderq?.setAttribute("data-slider-color", "success");
  }, []);

  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState<boolean>(false);
  const [searchStatus, setSearchStatus] = useState("");
  const onClickDelete = (productId: any) => {
    setProduct(productId);
    setDeleteModal(true);
  };

  const handleDeleteProduct = () => {
    if (product) {
      dispatch(deleteProducts({ product_id: product })).then(() => {
        fetchProductData("all");
      });
      setDeleteModal(false);
    }
  };

  // Delete Multiple
  const deleteMultiple = () => {
    const ele = document.querySelectorAll(".productCheckBox:checked");
    const del = document.getElementById("selection-element") as HTMLElement;
    ele.forEach((element: any) => {
      setTimeout(() => {}, 3000);
      del.style.display = "none";
    });
  };

  const columns = useMemo(
    () => [
      {
        header: "Product Code",
        accessorKey: "code",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <div className="d-flex align-items-center">
            <div className="flex-shrink-0 me-3">
              <div className="imageDivWrap avatar-sm bg-light rounded p-1">
                <img
                  src={`${IMAGE_URL}/${cell.row.original.thumbnail_image}`}
                  alt=""
                  className="img-fluid d-block imageWrap"
                  onError={(e: any) => {
                    e.target.onerror = null;
                    e.target.src = dummyImg;
                  }}
                />
              </div>
            </div>
            <div className="flex-grow-1">
              <h5 className="fs-14 mb-1">
                <Link to={`/product/editProduct/${cell?.row?.original?.id}`}>
                  {" "}
                  {cell.getValue()}
                </Link>
              </h5>
              <p className="text-muted mb-0">
                Category :{" "}
                <span className="fw-medium">
                  {" "}
                  {cell.row.original.category_name}
                </span>
              </p>
            </div>
          </div>
        ),
      },
      {
        header: "Stock",
        accessorKey: "available_stock",
        enableColumnFilter: false,
      },
      {
        header: "Price",
        accessorKey: "sale_rate",
        enableColumnFilter: false,
        cell: (cell) => {
          const price = cell?.getValue();
          if (typeof price === "string") {
            const prices = price?.split("-");
            if (prices?.length === 1) {
              // Single price
              return (
                <span>
                  {currencyFormat?.currency}
                  {prices[0]}
                </span>
              );
            } else {
              // Price range
              const [firstPrice, secondPrice] = prices;
              return (
                <span>
                  {currencyFormat?.currency}
                  {firstPrice}-{currencyFormat?.currency}
                  {secondPrice}
                </span>
              );
            }
          } else {
            return (
              <span>
                {currencyFormat?.currency}
                {price}
              </span>
            );
          }
        },
      },
      {
        header: "Orders",
        accessorKey: "orders_count",
        enableColumnFilter: false,
      },
      {
        header: "Variants",
        accessorKey: "total_variants",
        enableColumnFilter: false,
      },
      {
        header: "Action",
        enableSorting: false,
        cell: (cell: { row: { original: { id: number } } }) => (
          <div className="hstack gap-2">
            <Link to={`/product/editProduct/${cell?.row?.original?.id}`}>
              <button
                id={`editMode-${cell?.row?.original?.id}`}
                className="btn btn-sm btn-soft-info edit-list"
              >
                <i className="ri-pencil-fill align-bottom" />
                <ReactTooltip
                  place="bottom"
                  variant="info"
                  content="Edit"
                  anchorId={`editMode-${cell?.row?.original?.id}`}
                />
              </button>
            </Link>
            <button
              id={`delete-${cell?.row?.original?.id}`}
              className="btn btn-sm btn-soft-danger remove-list"
              onClick={() => {
                onClickDelete(cell?.row?.original?.id);
              }}
              disabled={role === RolesEnum?.STAFF}
            >
              <i className="ri-delete-bin-5-fill align-bottom" />
              <ReactTooltip
                place="bottom"
                variant="error"
                content="Delete"
                anchorId={`delete-${cell?.row?.original?.id}`}
              />
            </button>
          </div>
        ),
      },
    ],
    []
  );

  document.title = "Products";

  const handleSearchValueChange = (value: string) => {
    if (value !== searchValue) {
      setSearchValue(value);
      setCurrentPage(1);
    }
  };

  const fetchSearchData = () => {
    let payload: {
      searchBar: string;
      sortOrder: {
        field: string;
        order: string;
      };
      pageNumber: number;
      pageSize: number;
      product_status?: string;
    } = {
      searchBar: searchValue,
      sortOrder: { field: columnName, order: sortOrder },
      pageNumber: currentPage,
      pageSize: customPageSize,
    };
    if (searchStatus !== "all") {
      payload.product_status = searchStatus;
    }
    setIsLoading(true);
    listOfProduct(payload)
      .then((response) => {
        setSearchProductData(response?.data?.listOfProductData);
        setTotalRecords(response.data.totalRecordsCount);
        setTotalPages(response?.data?.totalPages);
        setTotalNumberOfRows(response?.data?.numberOfRows);
      })
      .catch((error) => {
        setSearchProductData([]);
        return error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="page-content">
      <Row>
        <Col lg={12}>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteProduct}
            onCloseClick={() => setDeleteModal(false)}
          />
          <DeleteModal
            show={deleteModalMulti}
            onDeleteClick={() => {
              deleteMultiple();
              setDeleteModalMulti(false);
            }}
            onCloseClick={() => setDeleteModalMulti(false)}
          />
          <Container>
            <Row className="mb-3">
              <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                <h5 className="fw-semibold mb-0">Products</h5>
              </div>
              {role !== RolesEnum?.STAFF && (
                <div className="col-auto order-2 order-sm-3 ms-auto">
                  <div className="hstack gap-2">
                    <Link
                      to={"/product/addProduct"}
                      className="btn btn-primary"
                    >
                      + Add
                    </Link>
                  </div>
                </div>
              )}
            </Row>
            <Row>
              <Col xl={12} lg={12}>
                <div>
                  <Card>
                    <div className="card-header border-0">
                      <Row className=" align-items-center">
                        <Col>
                          <Nav
                            className="nav-tabs-custom card-header-tabs border-bottom-0"
                            role="tablist"
                          >
                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: activeTab === "1" },
                                  "fw-semibold"
                                )}
                                onClick={() => {
                                  toggleTab("1", "all");
                                }}
                                href="#"
                              >
                                All{" "}
                                <span className="badge bg-info-subtle text-info align-middle rounded-pill ms-1">
                                  {countsData?.allCount}
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: activeTab === "2" },
                                  "fw-semibold"
                                )}
                                onClick={() => {
                                  toggleTab("2", "Publish");
                                }}
                                href="#"
                              >
                                Published{" "}
                                <span className="badge bg-success-subtle text-success align-middle rounded-pill ms-1">
                                  {countsData?.publishCount}
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: activeTab === "3" },
                                  "fw-semibold"
                                )}
                                onClick={() => {
                                  toggleTab("3", "Online");
                                }}
                                href="#"
                              >
                                Online{" "}
                                <span className="badge bg-warning-subtle text-warning align-middle rounded-pill ms-1">
                                  {countsData?.onlineCount}
                                </span>
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames(
                                  { active: activeTab === "4" },
                                  "fw-semibold"
                                )}
                                onClick={() => {
                                  toggleTab("4", "Draft");
                                }}
                                href="#"
                              >
                                Draft
                                <span className="badge bg-danger-subtle text-danger align-middle rounded-pill ms-1">
                                  {countsData?.draftCount}
                                </span>
                              </NavLink>
                            </NavItem>
                          </Nav>
                        </Col>
                        {/* <div className="col-auto">
                          <div id="selection-element">
                            <div className="my-n1 d-flex align-items-center text-muted">
                              Select{" "}
                              <div
                                id="select-content"
                                className="text-body fw-semibold px-1"
                              >
                                {dele}
                              </div>{" "}
                              Result{" "}
                              <button
                                type="button"
                                className="btn btn-link link-danger p-0 ms-3"
                                onClick={() => setDeleteModalMulti(true)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        </div> */}
                      </Row>
                    </div>
                    {isLoading && <LoaderBlur />}
                    <div className="card-body pt-0">
                      {productList && productList?.length > 0 ? (
                        <TableContainer
                          columns={columns}
                          data={
                            searchValue ? searchProductData : productList || []
                          }
                          isGlobalFilter={true}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          totalPages={totalPages}
                          totalRecords={totalRecords}
                          onSearch={handleSearchValueChange}
                          fetchSortingData={handleFetchSorting}
                          fetchData={handleFetchData}
                          customPageSize={customPageSize}
                          setCustomPageSize={setCustomPageSize}
                          divClass="table-responsive mb-1"
                          tableClass="mb-0 align-middle table-border"
                          theadClass="table-light text-muted table-wrap"
                          SearchPlaceholder="Search Product..."
                          manualPagination={true}
                          manualFiltering={true}
                          totalNumberOfRows={totalNumberOfRows}
                          manualSorting={true}
                        />
                      ) : (
                        <div className="py-4 text-center">
                          <div>
                            <i className="ri-search-line display-5 text-success"></i>
                          </div>

                          <div className="mt-4">
                            <h5>{notFound.loadingContent}</h5>
                          </div>
                        </div>
                      )}
                    </div>
                  </Card>
                </div>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </div>
  );
};

export default Product;
