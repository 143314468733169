// In this file i have add the too many ANY Type if i try to add the perticular type then its giving me error so in that i give some time please.
import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Input,
  Label,
  FormFeedback,
  Form,
  Spinner,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import classnames from "classnames";
import Dropzone from "react-dropzone";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { registerPlugin } from "react-filepond";
import Select from "react-select";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import CreatableSelect from "react-select/creatable";
import {
  PlaceHolderFormat,
  RequiredField,
} from "utils/helpers/validationRequiredMessages";
import { fileUploads } from "api/categoryApi";
import {
  addNewProduct as onAddNewProduct,
  updateProduct,
} from "../../slices/thunks";
import {
  deleteProductImage,
  deleteTags,
  listOfTags,
  viewProduct,
} from "api/productApi";
import { IMAGE_URL } from "api/apiServices";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import {
  addProductWithPurchaseProduct,
  generateProductBarcode,
} from "api/productPurchase";
import { toast } from "react-toastify";
import {
  categoryLabels,
  customerLabels,
  hsnLabels,
  metaLabels,
  productPurchase,
  productPurchaseEntry,
  title,
} from "utils/helpers/constant";
import LoaderBlur from "Components/Common/BlurLoader";
import DynamicColumnWithProduct from "./formikDynamicCoumn";
import {
  listOfCategoryOptions,
  listOfHsnOptions,
  listOfSubCategoryOptions,
} from "api/dropDownApi";
import { RolesEnum } from "utils/helpers/enums";
import { getRole } from "utils/helpers/sessionStores";
import imageCompression from "browser-image-compression";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface ProductVariants {
  id: number;
  mrp?: number;
  sale_rate?: number;
  color: string;
  size: string;
  quantity: number;
  variant_description?: string | null;
  is_deleted?: boolean;
}
interface OptionType {
  label: string;
  value: string;
}
interface ViewProductProps {
  code: string;
  product_name: string;
  product_description: string;
  sale_rate: string;
  mrp: number;
  meta_title: string;
  meta_keywords: string;
  meta_description: string;
  product_images: [];
  thumbnail: string;
  icon: string;
  product_status: string;
  category_id: {
    id: number;
    category_name: string;
  };
  sub_category_id: {
    id: number;
    sub_category_name: string;
  };
  hsn_code_id: {
    id: number;
    code: string;
  };
  product_tag: [
    {
      id: string;
      tag_id: {
        id: any;
        name: string;
      };
    }
  ];
  product_variant: [
    {
      id: number;
      price: number;
      color: string;
      size: string;
      quantity: number;
    }
  ];
  purchase_rate: string;
  images: [];
}
interface CategoryOptions {
  category_description: string;
  category_image: string;
  category_name: string;
  id: number;
}
interface SubCategoryOptions {
  id: number;
  subCategory_name: string;
}
interface HsnOption {
  id: number;
  code: string;
}
interface Variant {
  color: string;
  size: string;
  price: number;
  quantity: number;
  sale_rate: string | number;
  mrp: number;
}
interface VariantsData {
  forEach(arg0: (variant: Variant) => void): unknown;
  variants: Variant[];
}
interface Tags {
  id: string;
  name: string;
  tag_id: {
    name: string;
  };
}
interface PrefilledTag {
  id: string;
  tag_id: {
    name: string;
  };
}
interface ImageVariant {
  image: string;
  id: number;
  status: boolean;
  color_name: string;
  color_id: object;
}
interface OptionType {
  value: string;
  label: string;
}
interface VariantsData {
  color: string;
  colorDesc: string;
  fromSize: string;
  toSize: string;
  quantities: {};
}
interface SelectOption {
  value: number;
  label: string;
}
interface SelectOptionSize {
  id: number;
  size_range: string;
}

interface ProductData {
  size: string;
  fromSize: string;
  toSize: string;
  purchaseRate: number | "NaN";
  saleRate: string;
  mrp: string;
  colorList: ColorData[];
}
interface ColorData {
  color: string;
  quantities: { [key: string]: number }; // Assuming quantities are keyed by some identifier
}

const AddProduct = ({ isOpen }: any) => {
  const role = getRole();
  const history = useNavigate();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const { supplierPurchaseId } = useParams();
  const [customActiveTab, setCustomActiveTab] = useState<string>("1");
  const [viewProductData, setViewProductData] = useState<ViewProductProps>();

  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [productCategoryData, setProductCategoryData] = useState([]);
  const [productSubCategoryData, setProductSubCategoryData] = useState([]);
  const [productHsnData, setProductHsnData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [listOfColorIdData, setListOfColorIdData] = useState<any[]>([]);
  const [fileName, setFileName] = useState<{ [key: number]: any[] }>({});
  const [colorList, setColorList] = useState<any>(null);
  const [varientImages, setVarientImages] = useState<any>([]);
  const [fileNames, setFileNames] = useState<string[]>(
    viewProductData?.product_images || []
  );
  const [isGalleryUpload, setIsGalleryUpload] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [viewProductPurchaseRate, setViewProductPurchaseRate] = useState();
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [generateBarcode, setGenerateBarcode] = useState();
  const [pdfModal, setPdfModal] = useState(false);
  document.title = `${productId ? "Update Product" : "Create Product"}`;

  function handleAcceptedFiles(acceptedFiles: File[]) {
    acceptedFiles.forEach(async (file: File) => {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      };

      try {
        const compressedFile = await imageCompression(file, options);

        // Preserve original filename
        const newFile = new File([compressedFile], file.name, {
          type: compressedFile.type,
          lastModified: compressedFile.lastModified,
        });

        const formData = new FormData();
        formData.append("files", newFile);
        setIsGalleryUpload(true);

        const response = await fileUploads(formData);
        const uploadedFile = response?.data?.[0];

        acceptedFiles.map((file: File) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
          })
        );

        setFileNames((prevFileNames: string[]) => [
          ...prevFileNames,
          uploadedFile,
        ]);
        setSelectedFiles((prevFiles: any[]) => [
          ...prevFiles,
          {
            ...uploadedFile,
            preview: URL.createObjectURL(newFile),
            name: uploadedFile,
          },
        ]);
      } catch (error) {
        toast.error("Image compression failed");
      } finally {
        setIsGalleryUpload(false);
      }
    });
  }

  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const productStatus = [
    { label: "Draft", value: "Draft" },
    { label: "Publish", value: "Publish" },
    { label: "Online", value: "Online" },
  ];

  const [selectedThumbnail, setSelectedThumbnail] = useState<string>(
    viewProductData?.thumbnail || ""
  );
  const [selectedImage, setSelectedImage] = useState<string>(
    viewProductData?.icon || ""
  );
  const [listOfTagsData, setListOfTagsData] = useState<any>(
    viewProductData?.product_tag || []
  );
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [viewTags, setViewTags] = useState(
    viewProductData?.product_tag?.map((tag: any) => ({
      label: tag?.tag_id?.name,
      value: tag?.tag_id?.name,
    })) || []
  );
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [isThumbnailUploading, setIsThumbnailUploading] = useState(false);
  const [saleRateFromProductVariant, setSaleRateFromProductVariant] =
    useState(0);
  const [subCategoryIsLoading, setSubCategoryIsLoading] = useState(false);
  const [status, setStatus] = useState<string>("");

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      //preserv file name
      const newFile = new File([compressedFile], file.name, {
        type: compressedFile.type,
        lastModified: compressedFile.lastModified,
      });

      const formData = new FormData();
      formData.append("files", newFile);
      setIsImageUploading(true);

      const response = await fileUploads(formData);
      const files = response?.data?.[0];
      validation.setFieldValue("icon", files);
      setSelectedImage(files);
    } catch (error) {
      toast.error("Image compression failed");
    } finally {
      setIsImageUploading(false);
    }
  };

  const handleThumbnailChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1024,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      //preserv file name
      const newFile = new File([compressedFile], file.name, {
        type: compressedFile.type,
        lastModified: compressedFile.lastModified,
      });

      const formData = new FormData();
      formData.append("files", newFile);
      setIsThumbnailUploading(true);

      const response = await fileUploads(formData);
      const files = response?.data?.[0];
      validation.setFieldValue("thumbnail", files);
      setSelectedThumbnail(files);
    } catch (error) {
      toast.error("Image compression failed");
    } finally {
      setIsThumbnailUploading(false);
    }
  };

  const imageUrl = selectedImage
    ? `${IMAGE_URL}/${selectedImage}`
    : productId
      ? `${IMAGE_URL}/${viewProductData?.icon}`
      : `${IMAGE_URL}/${selectedImage}`;

  const thumbnailUrl = selectedThumbnail
    ? `${IMAGE_URL}/${selectedThumbnail}`
    : productId
      ? `${IMAGE_URL}/${viewProductData?.thumbnail}`
      : `${IMAGE_URL}/${selectedThumbnail}`;

  const [totalAmount, setTotalAmount] = useState<number | any>();
  const [totalQuantity, setTotalQuantity] = useState<number | undefined>();

  const customStyles = {
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: "#151b54",
      borderRadius: "10px",
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: "white",
      fontSize: "13px",
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      color: "white",
      ":hover": {
        color: "white",
      },
    }),
  };

  const fetchCategory = () => {
    setIsLoading(true);
    listOfCategoryOptions()
      .then((response) => {
        setProductCategoryData(
          response?.data?.map((category: CategoryOptions) => ({
            value: category?.id,
            label: category?.category_name,
          }))
        );
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchSubCategory = (categoryId: any) => {
    setSubCategoryIsLoading(true);
    listOfSubCategoryOptions({
      condition: {
        "category_id.id": categoryId,
      },
    })
      .then((response) => {
        setProductSubCategoryData(
          response?.data?.map((sub_category: SubCategoryOptions) => ({
            value: sub_category?.id,
            label: sub_category?.subCategory_name,
          }))
        );
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setSubCategoryIsLoading(false);
      });
  };

  const fetchHSN = () => {
    setIsLoading(true);
    listOfHsnOptions()
      .then((response) => {
        setProductHsnData(
          response?.data?.map((hsn_data: HsnOption) => ({
            value: hsn_data?.id,
            label: hsn_data?.code,
          }))
        );
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchTags = () => {
    setIsLoading(true);
    listOfTags({
      selectionCriteria: ["id", "name"],
    })
      .then((response) => {
        setListOfTagsData(
          response?.data?.map((tags: Tags) => ({
            value: tags?.id,
            label: tags?.name,
          }))
        );
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (viewProductData) {
      fetchSubCategory(viewProductData?.category_id?.id);
    }
  }, [viewProductData]);

  const handleTagsChange = (selectedOptions: any) => {
    setSelectedTags(selectedOptions);
    setViewTags(selectedOptions);
  };

  const handleCategoryChange = (selectedOption: OptionType) => {
    validation.setFieldValue("category_id", selectedOption?.value || "");
    if (selectedOption?.value) {
      fetchSubCategory(selectedOption?.value || "");
    }
  };

  useEffect(() => {
    if (viewProductData && viewProductData?.product_tag) {
      const initialTags = viewProductData?.product_tag.map((tag: any) => ({
        label: tag.tag_id.name,
        value: tag.tag_id.id,
        id: tag.id,
      }));
      setViewTags(initialTags);
      setSelectedImage(viewProductData?.icon);
      setSelectedThumbnail(viewProductData?.thumbnail);
    }
    if (viewProductData === undefined) {
      setCustomActiveTab("1");
    }
  }, [viewProductData]);

  const removeTag = (tagIdd: any, tagIndex: number) => {
    const updatedTags = [...selectedTags];
    const deletedTag = updatedTags.splice(tagIndex, 1)[0];
    if (deletedTag.id) {
      try {
        deleteTagFromBackend(tagIdd.id);
      } catch (error) {
        return error;
      }
    }

    setSelectedTags(updatedTags);
    setViewTags(updatedTags);
  };

  const deleteTagFromBackend = async (tagId: number) => {
    await deleteTags(tagId);
  };

  const fetchViewProduct = () => {
    setIsLoading(true);
    viewProduct(productId)
      .then((response) => {
        setViewProductData(response?.data?.productData);
        setViewProductPurchaseRate(response?.data?.purchaseRate);
        setStatus(response.data.productData.product_status);
      })
      .catch((error) => {
        return error;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (viewProductData && viewProductData?.product_images) {
      // const prefilledImages = viewProductData.product_images.map((image: ImageVariant) => ({
      //   id: image.id,
      //   name: `${image.image}`,
      //   preview: dummyImg || `${IMAGE_URL}/${image.image}`,
      //   status: image?.status,
      //   formattedSize: '',
      // }));
      const varientImage = viewProductData.product_images.map(
        (image: ImageVariant) => ({
          id: image.id,
          color: image.color_id,
          name: `${image.image}`,
          preview: dummyImg || `${IMAGE_URL}/${image.image}`,
          status: image?.status,
          formattedSize: "",
        })
      );
      const prefilledImages = viewProductData.thumbnail;
      setVarientImages(varientImage);

      setSelectedFiles(prefilledImages);
    }
  }, [viewProductData?.product_images]);

  useEffect(() => {
    Promise.all([fetchCategory(), fetchHSN(), fetchTags()]);
    calculateTotalAmount();
  }, []);

  useEffect(() => {
    fetchViewProduct();
  }, [productId]);

  const prefilledTags =
    viewProductData?.product_tag?.map((tag: PrefilledTag) => ({
      value: tag.id,
      label: tag.tag_id.name,
    })) || [];

  const calculateMRP = (
    purchase_rate: number,
    shipping_rate: number,
    converted_rate: number
  ) => {
    let mrp = ((purchase_rate + shipping_rate) / converted_rate).toFixed(2);
    validation.setFieldValue("mrp", mrp);
  };

  const conversionRateOptions = Array.from({ length: 6 }, (_, i) => ({
    value: i + productPurchaseEntry.convertedRate,
    label: `${i + productPurchaseEntry.convertedRate}`,
  }));

  const [updatedVariants, setUpdatedVariants] = useState([]);
  const [variantSizeRange, setVariantSizeRange] = useState<{
    value: number;
    label: string;
  }>();

  const [dataVariants, setDataVariants] = useState<ProductData>();

  const onUpdateState = (onUpdate: any) => {
    const colorIdMap = new Map<string, number>();
    Array.isArray(listOfColorIdData) &&
      listOfColorIdData.forEach((color) => {
        colorIdMap.set(color.label, color.value);
      });

    const transformedColorList = onUpdate?.colorList
      ?.map((colorEntry: any) => {
        const colorId = colorIdMap.get(colorEntry.color);

        if (colorId !== undefined && colorEntry.image) {
          const imagePaths: string[] = [];
          colorEntry?.image?.forEach((img: any) => {
            if (img) {
              imagePaths.push(img);
            }
          });
          return {
            color_id: colorId,
            images: imagePaths,
          };
        }
        return null;
      })
      .filter((entry: any) => entry !== null);

    setColorList(
      transformedColorList as {
        color_id: number;
        image_name: string[];
      }
    );

    setDataVariants(onUpdate);
    let newVariants: any = [];

    setVariantSizeRange(onUpdate?.size);

    let totalQuantity = 0;
    let totalAmount = 0;
    onUpdate?.colorList?.forEach((colorItem: any) => {
      const color = colorItem?.color;

      Object?.entries(colorItem?.quantities)?.forEach(
        ([size, quantityObj]: [string, any]) => {
          const { quantity, id } = quantityObj;
          const saleRate = parseFloat(onUpdate.saleRate);
          const amount = saleRate * quantity;

          if (!isNaN(quantity) && quantity !== null) {
            totalQuantity += quantity;
            totalAmount += amount;
          }
          if (!isNaN(quantity) && quantity !== null) {
            const newVariant: any = {
              id: id !== "" ? Number(id) : null,
              color,
              size,
              quantity,
              mrp: parseInt(onUpdate.mrp),
              sale_rate: parseInt(onUpdate.saleRate),
            };
            if (productId && id) {
              newVariant.id = id;
            }
            newVariants?.push(newVariant);
          } else if (id) {
            // Check if id is present and quantity is NaN
            // If id is present and quantity is NaN, mark the variant as deleted
            newVariants?.push({
              id,
              is_deleted: true,
            });
          }
          setTotalAmount(totalAmount);
          setTotalQuantity(totalQuantity);
        }
      );
    });

    // Check if new data is different from existing state before updating
    if (!arraysAreEqual(newVariants, updatedVariants)) {
      setUpdatedVariants(newVariants);
    }
  };
  // Function to compare two arrays
  const arraysAreEqual = (arr1: any[], arr2: any[]) => {
    if (arr1?.length !== arr2?.length) {
      return false;
    }
    for (let i = 0; i < arr1?.length; i++) {
      if (JSON?.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
        return false;
      }
    }
    return true;
  };

  const validation: any = useFormik({
    enableReinitialize: true,

    initialValues: {
      code: viewProductData?.code || "",
      category_id: viewProductData?.category_id?.id || "",
      sub_category_id: viewProductData?.sub_category_id?.id || "",
      product_name: viewProductData?.product_name || "",
      product_description: viewProductData?.product_description || "",
      hsn_code_id: viewProductData?.hsn_code_id?.id || "",
      // images: viewProductData?.product_images || [],
      thumbnail: viewProductData?.thumbnail || "",
      icon: viewProductData?.icon || "",
      meta_title: viewProductData?.meta_title || "",
      meta_keywords: viewProductData?.meta_keywords || "",
      meta_description: viewProductData?.meta_description || "",
      product_status: viewProductData?.product_status || "Draft",
      tags: [],
      purchase_rate: "",
      converted_rate: "",
      mrp: "",
      total_amount: "",
      total_quantity: "",
      shipping_rate: "",
      sale_rate: "",
      amountMRP: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required(RequiredField("Product code")),
      product_name: Yup.string().required(RequiredField("Product name")),
      category_id: Yup.string().required(RequiredField("Product category")),
      sub_category_id: Yup.string().required(
        RequiredField("product sub category")
      ),
      hsn_code_id: Yup.string().required(RequiredField("Product hsn code")),
      product_status: Yup.string().required(RequiredField("Product status")),
      ...(isOpen && {
        purchase_rate: Yup.number().required(RequiredField("Purchase rate")),
        shipping_rate: Yup.number().required(RequiredField("Shipping rate")),
        converted_rate: Yup.number().required(RequiredField("Converted rate")),
      }),
    }),
    onSubmit: (values) => {
      const updatedVariantsForProductPurchase = updatedVariants.map(
        (variant: any) => ({
          ...variant,
          purchase_rate: parseInt(validation.values.mrp),
        })
      );

      const payload: any = {};
      // below i have add the condition if child component fields are not fill then show this messages.

      if (
        dataVariants?.saleRate === "" ||
        dataVariants?.mrp === "" ||
        dataVariants?.colorList?.some((color: any) => color.color === "")
      ) {
        setFormSubmitted(true);
        return;
      }

      // Generate payload
      Object?.entries(values)?.forEach(([key, value]) => {
        // Exclude specific keys ('quantity' and 'sale_rate') and values unchanged from initial values
        if (key !== "quantity" && key !== "sale_rate") {
          if (validation.initialValues[key] !== value) {
            payload[key] = value;
          }
        }
      });

      payload.variants = updatedVariants ? updatedVariants : undefined;
      payload.tags = selectedTags
        ? selectedTags.map((tag: any) => tag.label)
        : undefined;
      // payload.images = fileNames ? fileNames : undefined;
      payload.thumbnail = selectedThumbnail ? selectedThumbnail : undefined;
      payload.icon = selectedImage ? selectedImage : undefined;
      payload.size_range_id = variantSizeRange ? variantSizeRange : undefined;
      payload.color_images = colorList;
      if (productId) {
        setIsLoading(true);
        dispatch(updateProduct({ productId, payload }))
          .then(() => { })
          .catch((err: any) => {
            return err;
          })
          .finally(() => {
            history("/product");
            setIsLoading(false);
          });
      } else if (supplierPurchaseId && isOpen === true) {
        setIsLoading(true);

        const payload = {
          product_item: {
            category_id: values?.category_id,
            sub_category_id: values?.sub_category_id,
            hsn_code_id: values?.hsn_code_id,
            code: values?.code,
            product_name: values?.product_name,
            color_images: colorList,
            product_description: values?.product_description,
            meta_title: values?.meta_title,
            meta_description: values?.meta_description,
            meta_keywords: values?.meta_keywords,
            product_status: values?.product_status,
            variants: updatedVariantsForProductPurchase,
            size_range_id: variantSizeRange,
            icon: selectedImage,
            tags: selectedTags.map((tag: any) => tag.label),
            // images: fileNames,
            thumbnail: selectedThumbnail,
          },
          purchase_rate: +values?.mrp,
          inr_purchase_rate: +values?.purchase_rate,
          shipping_rate: values?.shipping_rate,
          mrp: +values?.mrp,
          sale_rate: saleRateFromProductVariant,
          converted_rate: values?.converted_rate,
          total_quantity: totalQuantity,
          total_amount: parseInt(totalAmount),
          supplier_product_purchase_id: parseInt(supplierPurchaseId),
        };
        addProductWithPurchaseProduct(payload)
          .then((response) => {
            if (response?.statusCode == 400) {
              toast.error(response.message);
            } else if (response?.statusCode == 201) {
              navigate(`/productPurchase/${supplierPurchaseId}`);
              toast.success(response.message);
            }
          })
          .catch((err) => {
            toast.error(title.somethingWrong);
            return err;
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        const payload = {
          code: values?.code,
          category_id: values?.category_id,
          sub_category_id: values?.sub_category_id,
          hsn_code_id: values.hsn_code_id,
          product_name: values?.product_name,
          product_status: values?.product_status,
          product_description: values?.product_description,
          meta_title: values?.meta_title,
          meta_keywords: values?.meta_keywords,
          meta_description: values?.meta_description,
          size_range_id: variantSizeRange,
          variants: updatedVariants,
          tags: selectedTags.map((tag: any) => tag.label),
          // images: fileNames,
          color_images: colorList,
          thumbnail: selectedThumbnail,
          icon: selectedImage,
        };
        setIsLoading(true);
        dispatch(onAddNewProduct(payload))
          .then(() => { })
          .catch((err: any) => {
            return err;
          })
          .finally(() => {
            history("/product");
            setIsLoading(false);
          });
      }
    },
  });

  useEffect(() => {
    calculateMRP(
      validation.values.purchase_rate,
      validation.values.shipping_rate,
      validation.values.converted_rate
    );
  }, [
    validation.values.purchase_rate,
    validation.values.shipping_rate,
    validation.values.converted_rate,
  ]);

  const [thumbnailData, setThumbnailData] = useState(null);
  useEffect(() => {
    if (selectedFiles !== null) {
      const firstFileName = selectedFiles[0]?.name;
      setThumbnailData(firstFileName);
    }
  }, [selectedFiles]);

  function handleRadioChange(index: number) {
    const selectedFileName = selectedFiles[index]?.name;
    setThumbnailData(selectedFileName);
  }

  const removeFile = async (file: any | undefined, indexToRemove: number) => {
    if (file) {
      try {
        let files = [];
        files.push(file?.id);
        const payload = {
          productImageIds: files,
        };
        await deleteProductImage(payload);
      } catch (error) {
        return error;
      }
    }
    setSelectedFiles((prevFiles: []) =>
      prevFiles?.filter(
        (file: string, index: number) => index !== indexToRemove
      )
    );
  };

  const [justifyPillsTab, setJustifyPillsTab] = useState<string>("1");
  const justifyPillsToggle = (tab: string) => {
    if (justifyPillsTab !== tab) {
      setJustifyPillsTab(tab);
    }
  };

  useEffect(() => {
    if (viewProductData === undefined) {
      setJustifyPillsTab("1");
    } else {
      setJustifyPillsTab("1");
    }
  }, [viewProductData]);

  const [selectedSize, setSelectedSize] = useState<SelectOption | undefined>(
    undefined
  );
  const [saleRate, setSaleRate] = useState<any>(validation.values?.mrp || 0);
  const [mrp, setMRP] = useState<any>(saleRate || 0);
  const [sizeValidationMessage, setSizeValidationMessage] = useState("");
  const [saleRateValidationMessage, setSaleRateValidationMessage] =
    useState("");
  const [mrpValidationMessage, setMrpValidationMessage] = useState("");

  useEffect(() => {
    if (validation.values?.mrp) {
      setSaleRate(validation.values?.mrp);
      setMRP(validation.values?.mrp);
    }
  }, [validation.values?.mrp]);

  const calculateTotalAmount = () => {
    if (totalQuantity !== null && totalQuantity !== undefined) {
      setTotalAmount(saleRate * totalQuantity);
    } else {
      setTotalAmount(saleRate);
    }
  };

  useEffect(() => {
    calculateTotalAmount();
  }, [saleRate, totalQuantity]);

  useEffect(() => {
    const validations = [
      {
        condition: !selectedSize,
        messageSetter: setSizeValidationMessage,
        fieldName: "Size",
      },
      {
        condition: !saleRate,
        messageSetter: setSaleRateValidationMessage,
        fieldName: "Sale rate",
      },
      {
        condition: !mrp,
        messageSetter: setMrpValidationMessage,
        fieldName: "MRP",
      },
    ];

    // Set validation messages based on conditions
    validations?.forEach(({ condition, messageSetter, fieldName }) => {
      if (condition && formSubmitted) {
        messageSetter(RequiredField(fieldName));
      } else {
        messageSetter("");
      }
    });
  }, [formSubmitted]);

  const handleProductBarcode = () => {
    let data = {
      product_variant_ids: viewProductData?.product_variant.map(
        (item) => item.id
      ),
    };
    setSpinnerLoading(true);
    generateProductBarcode(data)
      .then((resp) => {
        setGenerateBarcode(resp.data);
        setPdfModal(true);
      })
      .catch((err) => {
        toast.error(title.somethingWrong);
        return err;
      })
      .finally(() => {
        setSpinnerLoading(false);
      });
  };

  const togglePdfModal = () => {
    setPdfModal(!pdfModal);
  };

  const getHeaders = (variants: ProductVariants[] = []): string[] => {
    const headers = new Set<string>();
    variants.forEach(({ color, size }) => {
      headers.add(`${color}/${size}`);
    });
    return Array.from(headers);
  };
  interface DataMap {
    [key: string]: {
      mrp: number | null;
      sale_rate: number | null;
      quantity: number | null;
    };
  }

  const mapDataByHeader = (headers: string[], variants: ProductVariants[] = []): DataMap => {
    const dataMap: DataMap = {};

    headers.forEach((header) => {
      dataMap[header] = { mrp: null, sale_rate: null, quantity: null };
    });

    variants.forEach(({ color, size, mrp, sale_rate, quantity }) => {
      const key = `${color}/${size}`;
      if (dataMap[key]) {
        dataMap[key] = { mrp: mrp || 0, sale_rate: sale_rate || 0, quantity: quantity || 0 };
      }
    });

    return dataMap;
  };
  const headers: string[] = getHeaders(viewProductData?.product_variant || []);
  const data: DataMap = mapDataByHeader(headers, viewProductData?.product_variant || []);

  return (
    <div className={isOpen === undefined ? "page-content" : ""}>
      <Container fluid>
        {isOpen === undefined && (
          <Row className="mb-2">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">
                {productId ? "Update Product" : "Create Product"}
              </h5>
            </div>
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                {spinnerLoading ? (
                  <Spinner className="me-6" />
                ) : (
                  productId && (
                    <button
                      className="btn btn-warning createTask"
                      type="button"
                      onClick={handleProductBarcode}
                    >
                      Print Label
                    </button>
                  )
                )}

                {generateBarcode && (
                  <Modal
                    isOpen={pdfModal}
                    toggle={togglePdfModal}
                    modalClassName="zoomIn"
                    centered
                  >
                    <ModalHeader
                      toggle={togglePdfModal}
                      className="p-3 bg-light p-3"
                    >
                      Product Barcode
                    </ModalHeader>
                    <ModalBody className="d-flex justify-content-center">
                      <iframe
                        src={`${IMAGE_URL}/${generateBarcode}`}
                        width="100%"
                        height="500px"
                        title="PDF Viewer"
                      />
                    </ModalBody>
                  </Modal>
                )}

                <button
                  className="btn btn-primary createTask"
                  type="button"
                  onClick={() => {
                    navigate("/product");
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </Row>
        )}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            setFormSubmitted(true);
            return false;
          }}
        >
          {isLoading && <LoaderBlur />}
          <Row>
            <Col lg={8}>
              <Card>
                <CardBody>
                  <div className="mb-1">
                    <Label className="form-label" htmlFor="product-title-input">
                      {productPurchase?.productCode}
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="product-title-input"
                      placeholder={PlaceHolderFormat(
                        productPurchase?.productCode
                      )}
                      name="code"
                      value={validation.values.code || ""}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      invalid={
                        !!(validation.errors.code && validation.touched.code)
                      }
                      disabled={
                        (viewProductData !== undefined &&
                          status !== "Online") ||
                        role === RolesEnum?.STAFF
                      }
                    />
                    {validation.errors.code && validation.touched.code ? (
                      <FormFeedback type="invalid">
                        {validation.errors.code}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-1">
                    <Label className="form-label" htmlFor="product-title-input">
                      {productPurchase?.productName}
                    </Label>
                    <Input
                      type="text"
                      className="form-control"
                      id="product-title-input"
                      placeholder={PlaceHolderFormat(
                        productPurchase?.productName
                      )}
                      name="product_name"
                      value={validation.values.product_name || ""}
                      onBlur={validation.handleBlur}
                      onChange={validation.handleChange}
                      disabled={
                        viewProductData?.product_status ===
                        productPurchaseEntry?.onlineStatus &&
                        status !== "Online"
                      }
                      invalid={
                        !!(
                          validation.errors.product_name &&
                          validation.touched.product_name
                        )
                      }
                    />
                    {validation.errors.product_name &&
                      validation.touched.product_name ? (
                      <FormFeedback type="invalid">
                        {validation.errors.product_name}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-1">
                    <label htmlFor="product_description" className="form-label">
                      {productPurchase?.productDescription}
                    </label>
                    <CKEditor
                      editor={ClassicEditor as any}
                      data={validation.values.product_description}
                      config={{
                        toolbar: [
                          "undo",
                          "redo",
                          "|",
                          "heading",
                          "|",
                          "bold",
                          "italic",
                          "link",
                          "insertTable",
                          "mediaEmbed",
                          "blockQuote",
                          "|",
                          "bulletedList",
                          "numberedList",
                          "outdent",
                          "indent",
                        ],
                      }}
                      disabled={
                        viewProductData?.product_status ===
                        productPurchaseEntry?.onlineStatus &&
                        status !== "Online"
                      }
                      onChange={(event, editor: any) => {
                        const data = editor.getData();
                        validation.setFieldValue("product_description", data);
                        editor.editing.view.change((writer: any) => {
                          writer.setStyle(
                            "height",
                            "200px",
                            editor.editing.view.document.getRoot()
                          );
                        });
                      }}
                    />
                    {validation.touched.product_description &&
                      validation.errors.product_description ? (
                      <div className="text-danger">
                        {validation.errors.product_description}
                      </div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={4}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    {productPurchase?.publish}
                  </h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-3">
                    <Label
                      htmlFor="choices-publish-status-input"
                      className="form-label"
                    >
                      {productPurchase?.status}
                    </Label>
                    <Select
                      name="product_status"
                      className="mb-3"
                      options={productStatus}
                      onChange={(selectedOption: OptionType) =>
                        validation.setFieldValue(
                          "product_status",
                          selectedOption.value
                        )
                      }
                      onBlur={validation.handleBlur}
                      isDisabled={
                        !(role === RolesEnum.STAFF && status !== "Online") &&
                        viewProductData?.product_status ===
                        productPurchaseEntry?.onlineStatus &&
                        status !== "Online"
                      }
                      value={productStatus.find(
                        (item: OptionType) =>
                          item.label === validation.values.product_status
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    {productPurchase?.productCategories}
                  </h5>
                </CardHeader>
                <CardBody>
                  <div>
                    <p className="text-muted mb-2">
                      {productPurchase?.select}{" "}
                      {productPurchase?.productCategory}
                    </p>
                    <Select
                      className={`${validation.touched.category_id &&
                        validation.errors.category_id &&
                        "is-invalid"
                        }`}
                      value={productCategoryData?.find(
                        (option: OptionType) =>
                          option.value === validation.values.category_id
                      )}
                      onChange={handleCategoryChange}
                      isDisabled={
                        role === RolesEnum?.STAFF ||
                        (viewProductData?.product_status ===
                          productPurchaseEntry?.onlineStatus &&
                          status !== "Online")
                      }
                      options={productCategoryData}
                    />
                    {validation.touched.category_id &&
                      validation.errors.category_id && (
                        <div className="error-message-drop">
                          {validation.errors.category_id}
                        </div>
                      )}
                  </div>

                  <div>
                    <p className="text-muted mb-2 mt-2">
                      {productPurchase?.select}{" "}
                      {productPurchase?.productSubCategory}
                    </p>
                    <Select
                      className={`${validation.touched.sub_category_id &&
                        validation.errors.sub_category_id &&
                        "is-invalid"
                        }`}
                      value={productSubCategoryData?.find(
                        (option: OptionType) =>
                          option.value === validation.values.sub_category_id
                      )}
                      onChange={(selectedOption: OptionType) =>
                        validation.setFieldValue(
                          "sub_category_id",
                          selectedOption?.value || ""
                        )
                      }
                      isDisabled={
                        role === RolesEnum?.STAFF ||
                        (viewProductData?.product_status ===
                          productPurchaseEntry?.onlineStatus &&
                          status !== "Online")
                      }
                      options={productSubCategoryData}
                    />
                    {validation.touched.sub_category_id &&
                      validation.errors.sub_category_id && (
                        <div className="error-message-drop">
                          {validation.errors.sub_category_id}
                        </div>
                      )}
                  </div>

                  <div>
                    <p className="text-muted mb-2 mt-2">
                      {productPurchase?.select} {hsnLabels?.hsn}{" "}
                      {hsnLabels?.hsnCodeAccessorKey}
                    </p>
                    <Select
                      className={`${validation.touched.hsn_code_id &&
                        validation.errors.hsn_code_id &&
                        "is-invalid"
                        }`}
                      value={productHsnData?.find(
                        (option: OptionType) =>
                          option.value === validation.values.hsn_code_id
                      )}
                      onChange={(selectedOption: OptionType) =>
                        validation.setFieldValue(
                          "hsn_code_id",
                          selectedOption?.value || ""
                        )
                      }
                      isDisabled={
                        role === RolesEnum?.STAFF ||
                        (viewProductData?.product_status ===
                          productPurchaseEntry?.onlineStatus &&
                          status !== "Online")
                      }
                      options={productHsnData}
                    />
                    {validation.touched.hsn_code_id &&
                      validation.errors.hsn_code_id && (
                        <div className="error-message-drop">
                          {validation.errors.hsn_code_id}
                        </div>
                      )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              {isOpen === true && (
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-2">
                          <Label htmlFor="purchase_rate">
                            {productPurchase?.purchaseRate} (
                            {productPurchase?.inrMark})
                          </Label>
                          <Input
                            type="number"
                            id="purchase_rate"
                            name="purchase_rate"
                            min="0"
                            value={validation.values.purchase_rate}
                            onChange={(e) => {
                              const newValue = parseFloat(e.target.value);
                              if (newValue >= 0 || e.target.value === "") {
                                validation.handleChange(e);
                              }
                            }}
                            invalid={
                              !!(
                                validation.touched.purchase_rate &&
                                validation.errors.purchase_rate
                              )
                            }
                            onBlur={validation.handleBlur}
                            placeholder={PlaceHolderFormat(
                              productPurchase?.purchaseRate
                            )}
                          />
                          {validation.touched.purchase_rate &&
                            validation.errors.purchase_rate ? (
                            <div className="error-message-drop">
                              {validation.errors.purchase_rate}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-2">
                          <Label>
                            {productPurchase?.shippingRate} (
                            {productPurchase?.inrMark})
                          </Label>
                          <Input
                            type="number"
                            title="shipping_rate"
                            name="shipping_rate"
                            placeholder={PlaceHolderFormat(
                              productPurchase?.shippingRate
                            )}
                            value={validation.values.shipping_rate}
                            min="0"
                            onBlur={validation.handleBlur}
                            onChange={(e) => {
                              const newValue = parseFloat(e.target.value);
                              if (newValue >= 0 || e.target.value === "") {
                                validation.handleChange(e);
                              }
                            }}
                            invalid={
                              !!(
                                validation.touched.shipping_rate &&
                                validation.errors.shipping_rate
                              )
                            }
                          ></Input>
                          {validation.touched.shipping_rate &&
                            validation.errors.shipping_rate ? (
                            <FormFeedback type="invalid">
                              {validation.errors.shipping_rate}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="mb-2">
                          <Label htmlFor="converted_rate">
                            {productPurchase?.productConvertedRate}
                          </Label>
                          <Select
                            className={`${validation.touched.converted_rate &&
                              validation.errors.converted_rate &&
                              "is-invalid"
                              }`}
                            id="converted_rate"
                            name="converted_rate"
                            options={conversionRateOptions}
                            value={conversionRateOptions.find(
                              (option: any) =>
                                option.value ===
                                validation.values.converted_rate
                            )}
                            onChange={(selectedOption: any) => {
                              validation.setFieldValue(
                                "converted_rate",
                                selectedOption.value
                              );
                              calculateMRP(
                                validation.values.purchase_rate,
                                validation?.values?.shipping_rate,
                                selectedOption.value
                              );
                            }}
                            placeholder={productPurchase?.select}
                          />

                          {validation.touched.converted_rate &&
                            validation.errors.converted_rate ? (
                            <div className="error-message-drop">
                              {validation.errors.converted_rate}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="mb-2">
                          <Label htmlFor="mrp">
                            {productPurchase?.purchaseRate} (
                            {productPurchase?.audMark})
                          </Label>
                          <Input
                            type="number"
                            id="mrp"
                            name="mrp"
                            value={validation.values.mrp}
                            readOnly
                            placeholder={productPurchase?.mrp}
                          />
                          {validation.touched.mrp && validation.errors.mrp ? (
                            <div className="text-danger">
                              {validation.errors.mrp}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col lg={4}>
              {isOpen === true && (
                <Card>
                  <CardBody>
                    <div className="mb-1">
                      <Label>{customerLabels?.totalAmount}</Label>
                      <Input
                        type="number"
                        title="total_amount"
                        name="total_amount"
                        value={totalAmount}
                        disabled
                        placeholder={PlaceHolderFormat(
                          customerLabels?.totalAmount
                        )}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={
                          !!(
                            validation.touched.total_amount &&
                            validation.errors.total_amount
                          )
                        }
                      ></Input>
                    </div>

                    <div className="mb-1">
                      <Label>{productPurchase?.totalQuantity}</Label>
                      <Input
                        type="number"
                        title="total_quantity"
                        name="total_quantity"
                        placeholder={PlaceHolderFormat(
                          productPurchase?.totalQuantity
                        )}
                        disabled
                        value={totalQuantity}
                        onBlur={validation.handleBlur}
                        onChange={validation.handleChange}
                        invalid={
                          !!(
                            validation.touched.total_quantity &&
                            validation.errors.total_quantity
                          )
                        }
                      ></Input>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    {productPurchase.addVariants}
                  </h5>
                </CardHeader>
                <CardBody>
                  <DynamicColumnWithProduct
                    purchaseRateAUD={validation.values?.mrp}
                    onFormSubmit={formSubmitted}
                    onUpdate={onUpdateState}
                    purchaseRate={viewProductPurchaseRate}
                    updateData={viewProductData}
                    setUpdateData={setViewProductData}
                    isOpen={isOpen}
                    status={status}
                    fetchViewProduct={fetchViewProduct}
                    setListOfColorIdData={setListOfColorIdData}
                    setFileName={setFileName}
                    varientImages={varientImages}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

          {productId && (
            <Row className="variant-details">
              <Card>
                <CardBody>
                  <h5 className="card-title mb-2">
                    Variant Details
                  </h5>
                  <div className="live-preview">
                    <div className="table-responsive">
                      <Table className="align-middle table-nowrap mb-0">
                        <thead className="table-light text-muted">
                          <tr>
                            <th className="border border-gray-300 p-2"></th>
                            {headers.map((header, index) => (
                              <th key={index} className="border border-gray-300 p-2">{header}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="border border-gray-300 p-2 font-bold">MRP</td>
                            {headers.map((header, index) => (
                              <td key={index} className="border border-gray-300 p-2">
                                {data[header].mrp ?? '-'}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="border border-gray-300 p-2 font-bold">Sale Rate</td>
                            {headers.map((header, index) => (
                              <td key={index} className="border border-gray-300 p-2">
                                {data[header].sale_rate ?? '-'}
                              </td>
                            ))}
                          </tr>
                          <tr>
                            <td className="border border-gray-300 p-2 font-bold">Quantity</td>
                            {headers.map((header, index) => (
                              <td key={index} className="border border-gray-300 p-2">
                                {data[header].quantity ?? '-'}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </div>

                </CardBody>
              </Card>
            </Row>
          )}

          <Row>
            <Col lg={8}>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">Product Gallery</h5>
                </CardHeader>
                <CardBody>
                  <div className="mb-4">
                    <h5 className="fs-14 mb-1">Product Icon</h5>
                    <p className="text-muted">
                      While performing the product search, this icon will be
                      displayed.
                    </p>
                    <div className="text-center">
                      <div className="position-relative d-inline-block">
                        <div className="position-absolute top-100 start-100 translate-middle">
                          <Label
                            htmlFor="customer-image-input"
                            className="mb-0"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="Select Image"
                          >
                            <div className="avatar-xs cursor-pointer">
                              <div className="avatar-title bg-light border rounded-circle text-muted">
                                <i className="ri-image-fill"></i>
                              </div>
                            </div>
                          </Label>
                          <Input
                            className="form-control d-none"
                            id="customer-image-input"
                            name="icon"
                            type="file"
                            disabled={
                              viewProductData?.product_status ===
                              productPurchaseEntry?.onlineStatus &&
                              status !== "Online"
                            }
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleImageChange}
                            invalid={
                              !!(
                                validation.touched.icon &&
                                validation.errors.icon
                              )
                            }
                          />
                        </div>
                        <div className="avatar-lg">
                          <div className="imageDivWrap avatar-title bg-light rounded">
                            {isImageUploading ? (
                              <Spinner
                                className="me-2"
                                size="sm"
                                color="dark"
                              />
                            ) : (
                              <img
                                src={imageUrl}
                                id="product-img"
                                alt=""
                                className="imageWrap avatar-md h-auto"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h5 className="fs-14 mb-1">Product Thumbnail</h5>
                    <p className="text-muted">
                      While performing the product search, this icon will be
                      displayed.
                    </p>
                    <div className="text-center">
                      <div className="position-relative d-inline-block">
                        <div className="position-absolute top-100 start-100 translate-middle">
                          <Label
                            htmlFor="thumbnail"
                            className="mb-0"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="Select Image"
                          >
                            <div className="avatar-xs cursor-pointer">
                              <div className="avatar-title bg-light border rounded-circle text-muted">
                                <i className="ri-image-fill"></i>
                              </div>
                            </div>
                          </Label>
                          <Input
                            className="form-control d-none"
                            id="thumbnail"
                            name="thumbnail"
                            type="file"
                            disabled={
                              viewProductData?.product_status ===
                              productPurchaseEntry?.onlineStatus &&
                              status !== "Online"
                            }
                            accept="image/png, image/gif, image/jpeg"
                            onChange={handleThumbnailChange}
                            invalid={
                              !!(
                                validation.touched.thumbnail &&
                                validation.errors.thumbnail
                              )
                            }
                          />
                        </div>
                        <div className="avatar-lg">
                          <div className="imageDivWrap avatar-title bg-light rounded">
                            {isThumbnailUploading ? (
                              <Spinner
                                className="me-2"
                                size="sm"
                                color="dark"
                              />
                            ) : (
                              <img
                                src={thumbnailUrl}
                                id="product-img"
                                alt=""
                                className="imageWrap avatar-md h-auto"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* {isGalleryUpload ? <Spinner className='me-2' size="sm" color="dark" /> : <div>
                    <h5 className="fs-14 mb-1">Product Gallery</h5>
                    <p className="text-muted">Add Product Gallery Images.</p> */}

                  {/* <Dropzone
                      onDrop={(acceptedFiles) => {
                        handleAcceptedFiles(acceptedFiles);
                        validation.setFieldValue('images', acceptedFiles);
                      }}
                      disabled={viewProductData?.product_status === productPurchaseEntry?.onlineStatus && status !== "Online"}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dz-clickable dropZoneMobile" {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3 mt-5">
                              <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                            </div>
                            <h5>Drop files here or click to upload.</h5>
                          </div>
                        </div>
                      )}
                    </Dropzone> */}
                  {/* <div className="list-unstyled mb-0" id="file-previews"> */}
                  {/* {selectedFiles.map((file: any, index: number) => {
                        return ( */}
                  {/* {selectedFiles && (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            // key={index + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <input
                                    type="radio"
                                    name="selectedImage"
                                    value={selectedFiles}
                                    // defaultChecked={file?.status === true || index === 0}
                                    onChange={(e: any) => handleRadioChange(e.target.value)} />
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light pl-4"
                                    alt={selectedFiles}
                                    src={thumbnailUrl}
                                    onError={(e: any) => {
                                      e.target.src = dummyImg;
                                    }} />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {selectedFiles.name}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{selectedFiles.formattedSize}</strong>
                                  </p>
                                </Col>
                                <Col>
                                  <button type="submit" className="btn btn-danger" onClick={(e) => {
                                    e.preventDefault();
                                    removeFile(selectedFiles, 0);
                                  }}><i className="ri-delete-bin-5-fill align-bottom" /></button>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                      //   );
                      // })}
                      )} */}
                  {/* </div>
                  </div>} */}
                </CardBody>
              </Card>
            </Col>
            <Col lg={4}>
              <Card>
                <CardBody>
                  <Nav
                    tabs
                    className="nav nav-tabs nav-tabs-custom nav-primary nav-justified mb-3"
                  >
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: justifyPillsTab === "1",
                        })}
                        onClick={() => justifyPillsToggle("1")}
                      >
                        Meta Tab
                      </NavLink>
                    </NavItem>
                  </Nav>

                  <TabContent
                    activeTab={justifyPillsTab}
                    className="text-muted"
                  >
                    <TabPane id="addproduct-meta-info" tabId="1">
                      <Row>
                        <Col lg={12}>
                          <div className="mb-1">
                            <label
                              className="form-label"
                              htmlFor="meta-title-input"
                            >
                              {metaLabels?.metaTitle}
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="meta-title-input"
                              name="meta_title"
                              placeholder={PlaceHolderFormat(
                                metaLabels?.metaTitle
                              )}
                              value={validation.values.meta_title || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              disabled={
                                role === RolesEnum?.STAFF ||
                                (viewProductData?.product_status ===
                                  productPurchaseEntry?.onlineStatus &&
                                  status !== "Online")
                              }
                              invalid={
                                validation.errors.meta_title &&
                                validation.touched.meta_title
                              }
                            />
                            {validation.errors.meta_title &&
                              validation.touched.meta_title && (
                                <FormFeedback type="invalid">
                                  {validation.errors.meta_title}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                        <Col lg={12}>
                          <div className="mb-1">
                            <label
                              className="form-label"
                              htmlFor="meta-keywords-input"
                            >
                              {metaLabels?.metaKeywords}
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="meta-keywords-input"
                              name="meta_keywords"
                              placeholder={PlaceHolderFormat(
                                metaLabels?.metaKeywords
                              )}
                              value={validation.values.meta_keywords || ""}
                              onBlur={validation.handleBlur}
                              disabled={
                                role === RolesEnum?.STAFF ||
                                (viewProductData?.product_status ===
                                  productPurchaseEntry?.onlineStatus &&
                                  status !== "Online")
                              }
                              onChange={validation.handleChange}
                              invalid={
                                validation.errors.meta_keywords &&
                                validation.touched.meta_keywords
                              }
                            />
                            {validation.errors.meta_keywords &&
                              validation.touched.meta_keywords && (
                                <FormFeedback type="invalid">
                                  {validation.errors.meta_keywords}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <div className="mb-1">
                            <label
                              className="form-label"
                              htmlFor="meta-description-input"
                            >
                              {metaLabels?.metaDescription}
                            </label>
                            <Input
                              type="textarea"
                              className="form-control"
                              id="meta-description-input"
                              name="meta_description"
                              placeholder={PlaceHolderFormat(
                                metaLabels?.metaDescription
                              )}
                              value={validation.values.meta_description || ""}
                              onBlur={validation.handleBlur}
                              onChange={validation.handleChange}
                              rows={2}
                              disabled={
                                role === RolesEnum?.STAFF ||
                                (viewProductData?.product_status ===
                                  productPurchaseEntry?.onlineStatus &&
                                  status !== "Online")
                              }
                              invalid={
                                validation.errors.meta_description &&
                                validation.touched.meta_description
                              }
                            />
                            {validation.errors.meta_description &&
                              validation.touched.meta_description && (
                                <FormFeedback type="invalid">
                                  {validation.errors.meta_description}
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <h5 className="card-title mb-0">
                    {productPurchase?.productTags}
                  </h5>
                </CardHeader>
                <CardBody>
                  <CreatableSelect
                    isMulti
                    options={listOfTagsData}
                    onChange={handleTagsChange}
                    value={viewTags}
                    formatCreateLabel={(value) => `+ Add Tag ${value}`}
                    maxMenuHeight={280}
                    menuShouldBlockScroll
                    styles={customStyles}
                  />
                  <div className="pt-1">
                    <h6>{productPurchase?.selectedTags}:</h6>
                    <div className="tagsWrap">
                      {selectedTags.map((tag: any, index: number) => (
                        <span
                          key={tag.value}
                          className="badge bg-primary-subtle text-primary m-1"
                        >
                          {tag.label}
                          <button
                            className="btn btn btn-sm p-0"
                            onClick={() => removeTag(tag, index)}
                          >
                            <i className="ri-close-line"></i>
                          </button>
                        </span>
                      ))}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="text-end mb-3">
            <button
              type="submit"
              className="btn btn-primary w-sm"
              disabled={
                // !validation?.dirty ||
                viewProductData?.product_status ===
                productPurchaseEntry?.onlineStatus && status !== "Online"
              }
            >
              {productId ? categoryLabels?.update : categoryLabels?.submit}
            </button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default AddProduct;
