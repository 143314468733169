import { Navigate } from "react-router-dom";
import Login from "../pages/Authentication/Login";
import DashboardEcommerce from "../pages/DashboardEcommerce";
import Category from "../pages/Category";
import SubCategory from "pages/SubCategory";
import EcommerceOrders from "pages/Orders";
import Product from "pages/Products";
import AddProduct from "pages/Products/addProduct";
import HSN from "pages/HSN";
import Suppliers from "pages/Suppliers";
import PuchaseProduct from "pages/Reports/puchaseProduct";
import Supplier from "pages/Reports/supplier";
import Sales from "pages/Reports/sales";
import Customer from "pages/Reports/customer";
import SalesOrderReturn from "pages/SalesOrderReturn";
import AddSalesOrderReturn from "pages/SalesOrderReturn/addSalesOrderReturn";
import ColorAndSize from "pages/ColorAndSize";
import CustomerList from "pages/CustomerList";
import OrderDetails from "pages/Orders/OrderDetails";
import Staff from "pages/Staff";
import AddStaff from "pages/Staff/addStaff";
import { RolesEnum } from "utils/helpers/enums";
import ProductPurchaseEntry from "pages/ProductPurchase";
import ProductPurchase from "pages/ProductPurchase/productPurchaseEntry";
import AddProductPurchase from "pages/ProductPurchase/addProductPurchase";
import CreateSalesOrder from "pages/Orders/addSalesOrder";
import FinancialYear from "pages/FinancialYear";
import StockDetails from "pages/StockDetails";
import Support from "pages/Support";
import ChangePassword from "pages/ChangePassword";
import ShippingRate from "pages/ShippingRate";

const role = sessionStorage.getItem("role");
const isStaff = role === RolesEnum.STAFF;

const authProtectedRoutes = [
  { path: "/dashboard", component: <DashboardEcommerce /> },
  { path: "/category", component: <Category /> },
  { path: "/subcategory", component: <SubCategory /> },
  { path: "/orders", component: <EcommerceOrders /> },
  { path: "/orders/order-details/:orderId", component: <OrderDetails /> },
  { path: "/orders/createSalesOrder", component: <CreateSalesOrder /> },
  {
    path: "/orders/updateSalesOrder/:orderId",
    component: <CreateSalesOrder />,
  },
  { path: "/product", component: <Product /> },
  { path: "/product/addProduct", component: <AddProduct /> },
  { path: "/product/editProduct/:productId", component: <AddProduct /> },
  { path: "/productPurchaseEntry", component: <ProductPurchaseEntry /> },
  { path: "/productPurchase", component: <ProductPurchase /> },
  {
    path: "/productPurchase/:productPurchaseId",
    component: <ProductPurchase />,
  },
  { path: "/suppliers", component: <Suppliers /> },
  { path: "/hsn", component: <HSN /> },
  { path: "/supplier", component: <Suppliers /> },
  { path: "/purchaseProductReport", component: <PuchaseProduct /> },
  {
    path: "/addProductPurchase/:supplierPurchaseId",
    component: <AddProductPurchase />,
  },
  {
    path: "/updateProductPurchase/:productId",
    component: <AddProductPurchase />,
  },
  { path: "/customerReport", component: <Customer /> },
  { path: "/supplierReport", component: <Supplier /> },
  { path: "/salesOrderReturn", component: <SalesOrderReturn /> },
  {
    path: "/salesOrderReturn/addSalesOrderReturn",
    component: <AddSalesOrderReturn />,
  },
  {
    path: "/salesOrderReturn/editSalesOrderReturn/:editId",
    component: <AddSalesOrderReturn />,
  },
  { path: "/color-size", component: <ColorAndSize /> },
  { path: "/salesReport", component: <Sales /> },
  { path: "/customerList", component: <CustomerList /> },
  { path: "/hsn", component: <HSN /> },
  { path: "/staff", component: <Staff /> },
  { path: "/staff/addStaff", component: <AddStaff /> },
  { path: "/staff/editStaff/:id", component: <AddStaff /> },
  { path: "/financialYear", component: <FinancialYear /> },
  { path: "/shippingRate", component: <ShippingRate /> },
  { path: "/stockDetails", component: <StockDetails /> },
  { path: "/support", component: <Support /> },
  { path: "/change-password", component: <ChangePassword /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const filteredRoutes = authProtectedRoutes.filter((route) => {
  if (isStaff) {
    return (
      route.path === "/dashboard" ||
      route.path === "/orders" ||
      route.path === "/orders/createSalesOrder" ||
      route.path === "/orders/updateSalesOrder/:orderId" ||
      route.path === "/product" ||
      route.path === "/product/editProduct/:productId"
    );
  } else {
    return true;
  }
});

const publicRoutes = [{ path: "/login", component: <Login /> }];
const redirectRoute = {
  path: "*",
  component: <Navigate to="/dashboard" replace />,
};
filteredRoutes.push(redirectRoute);

export { filteredRoutes as authProtectedRoutes, publicRoutes };
