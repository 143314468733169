import DeleteModal from "Components/Common/DeleteModal";
import TableContainer from "Components/Common/TableContainer";
import { addSupplier, deleteSupplier, editSupplier, listOfSuppliers, } from "api/supplierApi";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import { Button, Card, CardBody, Col, Container, Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from "reactstrap";
import { PlaceHolderFormat, RequiredField } from "utils/helpers/validationRequiredMessages";
import * as Yup from "yup";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { toast } from "react-toastify";
import { categoryLabels, loginLabels, notFound, supplierLabels, title, validationMessages } from "utils/helpers/constant";
import { ResponseStatusEnum } from "utils/helpers/enums";
import { listOfCity, listOfCountry, listOfState } from "api/commanApi";
import { emailRegex } from "utils/helpers/regexPatterns";
import LoaderBlur from "Components/Common/BlurLoader";
import ErrorDisplay from "utils/helpers/errorMessages";

type OptionType = {
    label: string;
    value: string;
};

interface CountryOptions {
    country_name: string;
    id: number;
    value: number;
};

interface StateOptions {
    province_name: string;
    id: number;
    value: number;
};

interface CityOptions {
    city_name: string;
    id: number;
    value: number;
};

interface EditSupplierData {
    id: number;
    supplier_name: string;
    email: string;
    contact_no: string;
    zip_code: string;
    address_line_1: string;
    address_line_2: string;
    country_id: {
        id: string,
        country_name: string
    };
    state_id: {
        id: string,
        state_name: string
    };
    city_id: {
        id: string,
        city_name: string
    };
};

const Suppliers = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [supplierModal, setSupplierModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [supplierDeleteModal, setSupplierDeleteModal] = useState(false);
    const [selectedId, setSelectedId] = useState<number>();
    const [suppliersData, setSuppliersData] = useState([]);
    const [searchSuppliersData, setSearchSuppliersData] = useState([]);
    const [listOfCountryOptions, setListOfCountryOptions] = useState<CountryOptions[]>([]);
    const [listOfStateOptions, setListOfStateOptions] = useState<StateOptions[]>([]);
    const [listOfCityOptions, setListOfCityOptions] = useState<CityOptions[]>([]);
    const [supplierEditData, setSupplierEditData] = useState<EditSupplierData>();
    const [cityIsLoading, setCityIsLoading] = useState(false);
    const [stateIsLoading, setStateIsLoading] = useState(false);
    const [countryIsLoading, setCountryIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
    const [searchValue, setSearchValue] = useState('');
    const [customPageSize, setCustomPageSize] = useState(5);
    const [columnName, setColumnName] = useState("id");
    const [sortOrder, setSortOrder] = useState("DESC");

    const handleFetchSorting = (page: number, id: string, order: string) => {
        setCurrentPage(page);
        setColumnName(id);
        setSortOrder(order);
    };

    const toggle = useCallback(() => {
        if (supplierModal) {
            setSupplierModal(false);
            setIsEdit(false);
            setSupplierEditData(undefined)
            validation.resetForm();
        } else {
            setSupplierModal(true);
        }
    }, [supplierModal]);

    const fetchSupplierData = () => {
        setIsLoading(true);
        listOfSuppliers({
            sortOrder: { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize
        })
            .then((response) => {
                setSuppliersData(response?.data?.listOfSupplierData);
                setTotalRecords(response?.data?.totalRecordsCount)
                setTotalPages(response?.data?.totalPages)
                setTotalNumberOfRows(response?.data?.numberOfRows)
                if (searchValue) {
                    fetchSearchData();
                }
            })
            .catch((err) => {
                return err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchSearchData = () => {
        setIsLoading(true);
        listOfSuppliers({
            sortOrder: { field: columnName, order: sortOrder },
            pageNumber: currentPage,
            pageSize: customPageSize,
            searchBar: searchValue
        })
            .then((response) => {
                setSearchSuppliersData(response?.data?.listOfSupplierData);
                setTotalRecords(response?.data?.totalRecordsCount)
                setTotalPages(response?.data?.totalPages)
                setTotalNumberOfRows(response?.data?.numberOfRows)
            })
            .catch((err) => {
                setSearchSuppliersData([]);
                return err;
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchCountryData = () => {
        setCountryIsLoading(true);
        listOfCountry({})
            .then((response) => {
                setListOfCountryOptions(
                    response?.data?.map((country: CountryOptions) => ({
                        value: country?.id,
                        label: country?.country_name,
                    }))
                );
            })
            .catch((error) => {
                return error;
            })
            .finally(() => {
                setCountryIsLoading(false);
            });
    };

    const fetchStateData = (countryId: string | undefined) => {
        setStateIsLoading(true);
        listOfState({
            condition: {
                country_id: countryId,
            },
        }).then((response) => {
            setListOfStateOptions(
                response?.data?.map((state: StateOptions) => ({
                    value: state?.id,
                    label: state?.province_name,
                }))
            );
        }).catch((error) => {
            return error;
        }).finally(() => {
            setStateIsLoading(false);
        });
    };

    const fetchCityData = (stateId: string | undefined) => {
        setCityIsLoading(true);
        listOfCity({
            condition: {
                province_id: stateId,
            },
        }).then((response) => {
            setListOfCityOptions(
                response?.data?.map((state: CityOptions) => ({
                    value: state?.id,
                    label: state?.city_name,
                }))
            );
        }).catch((error) => {
            return error;
        }).finally(() => {
            setCityIsLoading(false);
        });
    };

    useEffect(() => {
        Promise.all([fetchCountryData()]);
    }, []);

    const handleFetchData = (page: number) => {
        setCurrentPage(page); // Update the current page
    };

    const handleSearchValueChange = (value: string) => {
        if (value !== searchValue) {
            setSearchValue(value);
            setCurrentPage(1);
        }
    };

    useEffect(() => {
        if (searchValue) {
            fetchSearchData();
        } else {
            fetchSupplierData();
        }
    }, [currentPage, searchValue, customPageSize, sortOrder, columnName])

    useEffect(() => {
        if (searchValue === '') {
            setCurrentPage(1);
        }
    }, [searchValue]);

    useEffect(() => {
        if (supplierEditData) {
            fetchStateData(supplierEditData?.state_id?.id);
            fetchCityData(supplierEditData?.city_id?.id)
        }
    }, [supplierEditData]);

    const handleEditSupplierData = (cell: EditSupplierData) => {
        setIsEdit(true);
        setSupplierEditData(cell);
        setSupplierModal(true);
    };

    const onClickDelete = (id: number) => {
        setSelectedId(id);
        setSupplierDeleteModal(true);
    };

    const handleDeleteCategory = () => {
        setSupplierDeleteModal(false);
        deleteSupplier(selectedId)
            .then((response) => {
                if (response.statusCode === response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    toast.success(response.message);
                    fetchSupplierData();
                } else {
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                toast.error(title.somethingWrong);
                return err;
            })
            .finally(() => {
                setIsLoading(false);
                setSupplierDeleteModal(false);
            });
    };

    const handleAddSupplier = (value: object) => {
        setIsLoading(true);
        setSupplierModal(false);
        addSupplier(value)
            .then((response) => {
                if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    toast.success(response.message);
                    fetchSupplierData();
                } else {
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                toast.error(title.somethingWrong);
                return err;
            })
            .finally(() => {
                setIsLoading(false);
                validation.resetForm();
            });
    };

    const handleEditSupplier = (supplierId: number, values: object) => {
        setIsLoading(true);
        setSupplierModal(false);
        editSupplier(supplierId, values)
            .then((response) => {
                if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                    toast.success(response.message);
                    fetchSupplierData();
                } else {
                    toast.error(response.message);
                }
            })
            .catch((err) => {
                toast.error(title.somethingWrong);
                return err;
            })
            .finally(() => {
                setIsLoading(false);
                setIsEdit(false);
                setSupplierEditData(undefined);
                validation.resetForm();
            });
    };

    const SuppliersColumns = useMemo(
        () => [
            {
                header: `${supplierLabels.supplierName}`,
                accessorKey: `${supplierLabels.supplierNameAccessKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${loginLabels.email}`,
                accessorKey: `${supplierLabels.emailAccesskey}`,
                enableColumnFilter: false,
            },
            {
                header: `${supplierLabels.contactNo}`,
                accessorKey: `${supplierLabels.contactNoAccessKey}`,
                enableColumnFilter: false,
            },
            {
                header: `${supplierLabels.address}`,
                accessorKey: `${supplierLabels.addressLine1AccessKey}`,
                enableColumnFilter: false,
                cell: (cell: { row: { original: EditSupplierData } }) => (
                    <div className="word-wrap-custom">
                        {cell.row.original.address_line_1}{cell.row.original.address_line_2 !== null && ", "}
                        {cell.row.original.address_line_2}
                    </div>
                ),
            },
            {
                header: `${categoryLabels.actions}`,
                enableSorting: false,
                cell: (cell: { row: { original: EditSupplierData } }) => (
                    <div className="hstack gap-2">
                        <button
                            id={`editMode-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => handleEditSupplierData(cell?.row?.original)}
                        >
                            <i className="ri-pencil-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content="Edit"
                                anchorId={`editMode-${cell?.row?.original?.id}`}
                            />
                        </button>
                        <button
                            id={`delete-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-danger remove-list"
                            onClick={() => {
                                onClickDelete(cell?.row?.original?.id);
                            }}
                        >
                            <i className="ri-delete-bin-5-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="error"
                                content="Delete"
                                anchorId={`delete-${cell?.row?.original?.id}`}
                            />
                        </button>
                    </div>
                ),
            },
        ], []);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            supplier_name: supplierEditData?.supplier_name || "",
            contact_no: supplierEditData?.contact_no || "",
            email: supplierEditData?.email || "",
            address_line_1: supplierEditData?.address_line_1 || "",
            address_line_2: supplierEditData?.address_line_2 || "",
            zip_code: supplierEditData?.zip_code || "",
            country_id: supplierEditData?.country_id?.id || '',
            state_id: supplierEditData?.state_id?.id || '',
            city_id: supplierEditData?.city_id?.id || '',
        },
        validationSchema: Yup.object().shape({
            supplier_name: Yup.string().required(RequiredField(supplierLabels.supplier)),
            contact_no: Yup.string()
                .matches(/^\d{10}$/, supplierLabels.contactMatches)
                .required(RequiredField(supplierLabels.contactNo)),
            email: Yup.string()
                .email(validationMessages.format(loginLabels.email))
                .matches(emailRegex, validationMessages.format(loginLabels.email))
                .required(validationMessages.required(loginLabels.email)),
            address_line_1: Yup.string().required(RequiredField(supplierLabels.addressLine1)),
            zip_code: Yup.string()
                .matches(/^\d{4}$/, supplierLabels.zipCodeMatches)
                .required(RequiredField(supplierLabels.zipCode)),
            country_id: Yup.string().required(RequiredField(supplierLabels.county)),
            state_id: Yup.string().required(RequiredField(supplierLabels.state)),
            city_id: Yup.string().required(RequiredField(supplierLabels.city)),
        }),
        onSubmit: (value: any) => {
            if (supplierEditData) {
                handleEditSupplier(supplierEditData?.id, { ...value, zip_code: value.zip_code.toString() });
            } else {
                handleAddSupplier({ ...value, zip_code: value.zip_code.toString() });
            }
        },
    });

    document.title = supplierLabels.title;

    return (
        <div className="page-content">
            <DeleteModal
                show={supplierDeleteModal}
                onDeleteClick={handleDeleteCategory}
                onCloseClick={() => setSupplierDeleteModal(false)}
            />
            <Container>
                <Row className="mb-3">
                    <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                        <h5 className="fw-semibold mb-0">{supplierLabels.title}</h5>
                    </div>
                    <div className="col-auto order-2 order-sm-3 ms-auto">
                        <div className="hstack gap-2">
                            <button
                                className="btn btn-primary createTask"
                                type="button"
                                onClick={() => {
                                    setSupplierModal(true);
                                }}
                            >
                                + {categoryLabels.addCategory}
                            </button>
                        </div>
                    </div>
                </Row>
                <Card>
                    <CardBody>
                        {isLoading && <LoaderBlur />}
                        {suppliersData && suppliersData?.length > 0 ? (
                            <TableContainer
                                columns={SuppliersColumns}
                                data={searchValue ? searchSuppliersData : suppliersData || []}
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                totalPages={totalPages}
                                totalRecords={totalRecords}
                                totalNumberOfRows={totalNumberOfRows}
                                fetchData={handleFetchData}
                                fetchSortingData={handleFetchSorting}
                                onSearch={handleSearchValueChange}
                                isGlobalFilter={true}
                                customPageSize={customPageSize}
                                setCustomPageSize={setCustomPageSize}
                                divClass="table-responsive mb-1"
                                tableClass="mb-0 align-middle table-border"
                                theadClass="table-light text-muted"
                                SearchPlaceholder="Search Supplier"
                                manualPagination={true}
                                manualFiltering={true}
                                manualSorting={true}
                            />
                        ) : (
                            <div className="py-4 text-center">
                                <div>
                                    <i className="ri-search-line display-5 text-success"></i>
                                </div>

                                <div className="mt-4">
                                    <h5>{notFound.loadingContent}</h5>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>

                <Modal
                    isOpen={supplierModal}
                    toggle={toggle}
                    modalClassName="zoomIn"
                    centered
                >
                    <Form onSubmit={validation.handleSubmit}>
                        <ModalHeader toggle={toggle} className="p-3 bg-light p-3">
                            {isEdit ? categoryLabels.update : categoryLabels.create} {supplierLabels.title}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="supplier_name">
                                            {supplierLabels.supplierName}
                                        </Label>
                                        <Input
                                            type="text"
                                            id={supplierLabels.supplierNameAccessKey}
                                            name={supplierLabels.supplierNameAccessKey}
                                            value={validation.values.supplier_name}
                                            placeholder={PlaceHolderFormat(supplierLabels.supplierName)}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.supplier_name && validation.touched.supplier_name)}
                                        />
                                        <ErrorDisplay errors={validation.errors.supplier_name} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="contact_no">
                                            {supplierLabels.contactNo}
                                        </Label>
                                        <Input
                                            type="tel"
                                            id={supplierLabels.contactNoAccessKey}
                                            name={supplierLabels.contactNoAccessKey}
                                            maxLength={10}
                                            value={validation.values.contact_no}
                                            placeholder={PlaceHolderFormat(supplierLabels.contactNo)}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.contact_no && validation.touched.contact_no)}
                                        />
                                        <ErrorDisplay errors={validation.errors.contact_no} />

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="email">
                                            {loginLabels.email}
                                        </Label>
                                        <Input
                                            type="email"
                                            id={supplierLabels.emailAccesskey}
                                            name={supplierLabels.emailAccesskey}
                                            value={validation.values.email}
                                            placeholder={PlaceHolderFormat(supplierLabels.emailAccesskey)}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.email && validation.touched.email)}
                                        />
                                        <ErrorDisplay errors={validation.errors.email} />

                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="address_line_1">
                                            {supplierLabels.addressLine1}
                                        </Label>
                                        <Input
                                            type="textarea"
                                            id={supplierLabels.addressLine1AccessKey}
                                            name={supplierLabels.addressLine1AccessKey}
                                            value={validation.values.address_line_1}
                                            placeholder={PlaceHolderFormat(supplierLabels.addressLine1)}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            rows={1}
                                            invalid={!!(validation.errors.address_line_1 && validation.touched.address_line_1)}
                                        />
                                        <ErrorDisplay errors={validation.errors.address_line_1} />

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="address">
                                            {supplierLabels.addressLine2}
                                        </Label>
                                        <Input
                                            type="textarea"
                                            id={supplierLabels.addressLine2AccessKey}
                                            name={supplierLabels.addressLine2AccessKey}
                                            value={validation.values.address_line_2}
                                            placeholder={PlaceHolderFormat(supplierLabels.address)}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            rows={1}
                                            invalid={!!(validation.errors.address_line_2 && validation.touched.address_line_2)}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="zip_code">
                                            {supplierLabels.zipCodeName}
                                        </Label>
                                        <Input
                                            type="number"
                                            id={supplierLabels.zipCodeAccessKey}
                                            name={supplierLabels.zipCodeAccessKey}
                                            maxLength={6}
                                            value={validation.values.zip_code}
                                            placeholder={PlaceHolderFormat(supplierLabels.zipCodeName)}
                                            onBlur={validation.handleBlur}
                                            onChange={validation.handleChange}
                                            invalid={!!(validation.errors.zip_code && validation.touched.zip_code)}
                                        />
                                        <ErrorDisplay errors={validation.errors.zip_code} />

                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={4} className="mb-3">
                                    {countryIsLoading ?
                                        <div className="d-flex align-items-center justify-content-center spinner">
                                            <Spinner size="sm" />
                                        </div>
                                        :
                                        <>
                                            <Label>{supplierLabels.county}</Label>
                                            <Select
                                                value={listOfCountryOptions?.find((option: any) => option.value === validation.values.country_id)} //here i have add the any type because of i need to add the type if here i add the value and label in object then conflict with the CountryOption Interface same as well as the in city, state
                                                onChange={(selectedOption: OptionType) => {
                                                    validation.setFieldValue(
                                                        "country_id",
                                                        selectedOption?.value || ""
                                                    );
                                                    fetchStateData(selectedOption?.value);
                                                }}
                                                options={listOfCountryOptions}
                                                invalid={!!(validation.errors.country_id && validation.touched.country_id)}
                                            />
                                            <ErrorDisplay errors={validation.errors.country_id} />

                                        </>
                                    }
                                </Col>

                                <Col lg={4} className="mb-3">
                                    {stateIsLoading ?
                                        <div className="d-flex align-items-center justify-content-center spinner">
                                            <Spinner size="sm" />
                                        </div>
                                        :
                                        <>
                                            <Label>{supplierLabels.state}</Label>
                                            <Select
                                                value={listOfStateOptions?.find((option: any) => option.value === validation.values.state_id)}
                                                onChange={(selectedOption: OptionType) => {
                                                    validation.setFieldValue(
                                                        "state_id",
                                                        selectedOption?.value || ""
                                                    );
                                                    fetchCityData(selectedOption?.value);
                                                }}
                                                options={listOfStateOptions}
                                                invalid={!!(validation.errors.state_id && validation.touched.state_id)}
                                            />
                                            <ErrorDisplay errors={validation.errors.state_id} />

                                        </>
                                    }
                                </Col>

                                <Col lg={4} className="mb-3">
                                    {cityIsLoading ?
                                        <div className="d-flex align-items-center justify-content-center spinner">
                                            <Spinner size="sm" />
                                        </div>
                                        :
                                        <>
                                            <Label>{supplierLabels.city}</Label>
                                            <Select
                                                value={listOfCityOptions?.find((option: any) => option.value === validation.values.city_id)}
                                                onChange={(selectedOption: OptionType) =>
                                                    validation.setFieldValue("city_id", selectedOption?.value || "")
                                                }
                                                options={listOfCityOptions}
                                                invalid={!!(validation.errors.city_id && validation.touched.city_id)}
                                            />
                                            <ErrorDisplay errors={validation.errors.city_id} />
                                        </>
                                    }
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="none"
                                className="btn btn-ghost-success"
                                onClick={toggle}
                            >
                                {categoryLabels.cancel}
                            </Button>
                            <Button type="submit" color="primary">
                                {isEdit ? categoryLabels.update : categoryLabels.submit}
                            </Button>
                        </ModalFooter>
                    </Form>
                </Modal>
            </Container>
        </div>
    );
};

export default Suppliers;
