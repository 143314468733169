import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import { Card, CardBody, CardHeader, Col, Form, FormFeedback, Input, Label, Row, Spinner, Modal, ModalBody, ModalHeader, Container } from 'reactstrap'
import { PlaceHolderFormat, RequiredField } from 'utils/helpers/validationRequiredMessages';
import * as Yup from 'yup';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/light.css'
import { listOfSuppliers } from 'api/supplierApi';
import { currencyFormat, notFound, supplierLabels, title } from 'utils/helpers/constant';
import { listOfCity, listOfState } from 'api/commanApi';
import { addedProductDelete, createBillNumber, editProductPurchaseEntry, listOfAddedProduct, supplierProductPurchase, viewAddedProduct, viewProductPurchaseEntry, generateProductBarcode } from 'api/productPurchase';
import { ResponseStatusEnum } from 'utils/helpers/enums';
import { toast } from 'react-toastify';
import Loader from 'Components/Common/loader';
import TableContainer from 'Components/Common/TableContainer';
import { Tooltip as ReactTooltip } from "react-tooltip";
import DeleteModal from 'Components/Common/DeleteModal';
import { useNavigate, useParams } from 'react-router-dom';
import { IMAGE_URL } from 'api/apiServices';
import { listOfSuppliesOptions } from 'api/dropDownApi';
import { dateFormatChange } from 'utils/helpers/comman';
import moment from 'moment';
import LoaderBlur from 'Components/Common/BlurLoader';
interface StateOptions {
    province_name: string;
    id: number;
    value: number;
};
interface CityOptions {
    city_name: string;
    id: number;
    value: number;
};

type OptionType = {
    label: string;
    value: string;
};

type ProductData = {
    id: number;
    code: string;
    product_name: string;
    purchase_rate: number | null;
    total_amount: number | null;
    product_purchase_variant: ProductVaraint[]
}
interface ProductVaraint {
    id: number;
    mrp: number;
    sale_rate: number;
    quantity: number;
    color: string;
    size: string;
}
interface ViewProductPurchaseData {
    id: number,
    supplier_id: SupplierProps
    bill_date: any,
    bill_no: number,
    challan_no: number,
    doc_date: string,
    gst_type: string,
    gst_no: string,
    discount_type: string,
    bill_discount: number,
    round_off: number,
    total_mrp: number,
    total_quantity: number,
    net_amount: number,
    status: string,
    city_id: City,
    state_id: State,
    product_purchase: ProductPurchaseProps[]
}
interface SupplierProps {
    id: number,
    supplier_name: string
}
interface City {
    "id": number,
    "province_id": number,
    "city_name": string
}
interface State {
    "id": number,
    "country_id": number,
    "province_name": string
}
interface ProductPurchaseProps {
    id: number,
    code: string,
    product_name: string,
    product_description: string,
    total_quantity: number,
    total_amount: number,
    category_id: Category
    sub_category_id: SubCategory,
    hsn_code_id: HSN
}
interface Category {
    "id": number,
    "category_name": string
}
interface SubCategory {
    "id": number,
    "sub_category_name": string
}
interface HSN {
    "id": number,
    "code": string
}
// Can you please allow commented code for future inhancement
const ProductPurchase = () => {
    const { productPurchaseId }: any = useParams();
    const navigate = useNavigate();
    const currentDate = new Date();

    const [billDate, setBillDate] = useState(currentDate);
    const [docDate, setDocdate] = useState(currentDate);
    const [isLoading, setIsLoading] = useState(false);
    const [supplierNameData, setSupplierNameData] = useState([]);
    const [listOfStateOptions, setListOfStateOptions] = useState<StateOptions[]>([]);
    const [listOfCityOptions, setListOfCityOptions] = useState<CityOptions[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [supplierPurchaseId, setSupplierPurchaseId] = useState();
    const [selectedSupplierData, setSelectedSupplierData] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState();
    const [listOfAddedProductData, setListOfAddedProductData] = useState<any>([]);
    const [updatedProductData, setUpdatedProductData] = useState([]);
    const [isSaveDraftSuccess, setIsSaveDraftSuccess] = useState<boolean>(false);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [selectedId, setSelectedId] = useState<object | undefined>();
    const [addedProductDeleteModal, setAddedProductDeleteModal] = useState(false);
    const [billNumberData, setBillNumberData] = useState();
    const [viewProductPurchaseData, setViewProductPurchaseData] = useState<ViewProductPurchaseData>();
    const [viewAddedProductData, setViewAddedProductData] = useState();
    const [statusOfProductPurchase, setStatusOfProductPurchase] = useState('Draft');
    const [supplierProductPurchaseStatus, setSupplierProductPurchaseStatus] = useState(false);
    const [generateBarcode, setGenerateBarcode] = useState();
    const [spinnerLoading, setSpinnerLoading] = useState(false);
    const [pdfModal, setPdfModal] = useState(false);
    const [isViewLoading, setIsViewLoading] = useState(false);

    const dateFormaterChange = (date: any) => {
        return moment(date).format("YYYY-MM-DD");
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const togglePdfModal = () => {
        setPdfModal(!pdfModal);
    };

    const fetchListOfAddedProduct = () => {
        setIsLoading(true);
        listOfAddedProduct({ supplier_product_purchase_id: productPurchaseId }).then((response) => {
            setUpdatedProductData(response?.data?.listOfProduct)
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const fetchData = () => {
        setIsLoading(true);
        listOfSuppliesOptions({})
            .then(response => {
                setSupplierNameData(response?.data?.map((supplierData: any) => ({
                    value: supplierData?.id,
                    label: supplierData?.supplier_name,
                    data: supplierData
                })));
            })
            .catch((error) => {
                return error
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchStateData = () => {
        setIsLoading(true);
        listOfState({}).then((response) => {
            setListOfStateOptions(
                response?.data?.map((state: StateOptions) => ({
                    value: state?.id,
                    label: state?.province_name,
                }))
            );
        }).catch((error) => {
            return error;
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const fetchCityData = () => {
        setIsLoading(true);
        listOfCity({}).then((response) => {
            setListOfCityOptions(
                response?.data?.map((state: CityOptions) => ({
                    value: state?.id,
                    label: state?.city_name,
                }))
            );
        }).catch((error) => {
            return error;
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const fetchBillNumber = () => {
        setIsLoading(true);
        createBillNumber({}).then((response) => {
            setBillNumberData(response?.data);
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        })
    }

    const fetchViewProductPurchaseEntry = () => {
        setIsViewLoading(true);
        viewProductPurchaseEntry(productPurchaseId).then((response) => {
            setViewProductPurchaseData(response?.data);
            setIsSaveDraftSuccess(true);
        }).catch((err) => {
            return err
        }).finally(() => {
            setIsViewLoading(false);
        })
    }

    const fetchAddEditProductPurchase = () => {
        setIsLoading(true);
        viewProductPurchaseEntry(supplierPurchaseId).then((response) => {
            if (response?.data?.id) {
                setViewProductPurchaseData(response?.data);
            }
            setIsSaveDraftSuccess(true);
        }).catch((err) => {
            return err
        }).finally(() => {
            setIsLoading(false);
        })
    }
    //As of now i remove this but need for future

    // const [refreshTable, setRefreshTable] = useState(false);

    // useEffect(() => {
    //     if (refreshTable) {
    //         fetchListOfAddedProduct();
    //         setRefreshTable(false);
    //     }
    // }, [refreshTable])

    useEffect(() => {
        fetchViewProductPurchaseEntry();
    }, [productPurchaseId])

    useEffect(() => {
        fetchAddEditProductPurchase();
    }, [supplierPurchaseId])

    useEffect(() => {
        setSupplierPurchaseId(supplierPurchaseId)
    }, [supplierPurchaseId])

    useEffect(() => {
        Promise.all([fetchData(), fetchStateData(), fetchCityData(), fetchBillNumber(),])
        if (productPurchaseId !== undefined) {
            fetchListOfAddedProduct();
        }
    }, []);

    const handleSupplierSelect = (selectedOption: any) => {
        setSelectedSupplier(selectedOption);
        setSelectedSupplierData(selectedOption?.data);
        validation.setFieldValue('supplier_id', selectedOption?.value || '')
        if (selectedOption) {
            validation.setFieldValue('state_id', selectedOption?.data?.state_id?.id);
            validation.setFieldValue('city_id', selectedOption?.data?.city_id?.id);
        }
    };

    const handleEditMode = (productId: number) => {
        setIsLoading(true);
        navigate(`/updateProductPurchase/${productId}`);
        viewAddedProduct(productId).then((response) => {
            setViewAddedProductData(response?.data);
        }).catch((err) => {
            return err;
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const onClickDelete = (id: number, product_purchase_variant_id: { id: number }) => {
        const payloadDelete = {
            product_purchase_id: id,
            purchase_variant_id: product_purchase_variant_id?.id
        }
        setSelectedId(payloadDelete);
        setAddedProductDeleteModal(true);
    };

    const handleDeleteAddedProduct = () => {
        setAddedProductDeleteModal(false);
        if (selectedId) {
            addedProductDelete(selectedId)
                .then((response) => {
                    if (
                        response.statusCode &&
                        ResponseStatusEnum.SUCCESS.includes(response?.statusCode)
                    ) {
                        toast.success(response.message);
                        fetchListOfAddedProduct();
                    } else {
                        toast.error(response.message);
                    }
                })
                .catch((err) => {
                    toast.error(title.somethingWrong);
                    return err;
                })
                .finally(() => {
                    setIsLoading(false);
                    setAddedProductDeleteModal(false);
                });
        }
    };

    const handleProductBarcode = () => {
        let data = {
            supplier_product_purchase_id: productPurchaseId
        }
        setSpinnerLoading(true);
        generateProductBarcode(data)
            .then((resp) => {
                setGenerateBarcode(resp.data)
                setPdfModal(true);
            })
            .catch((err) => {
                toast.error(title.somethingWrong);
                return err;
            })
            .finally(() => {
                setSpinnerLoading(false);
            })
    }

    const AddProductColumns = useMemo(
        () =>
            [{
                header: "Product Code",
                accessorKey: 'code',
                enableColumnFilter: false,
            },
            {
                header: "Product Name",
                accessorKey: 'product_name',
                enableColumnFilter: false,
            },
            {
                header: "Color",
                accessorKey: 'color',
                enableColumnFilter: false,
            },
            {
                header: "Size",
                accessorKey: 'size',
                enableColumnFilter: false,
            },
            {
                header: "Quantity",
                accessorKey: 'quantity',
                enableColumnFilter: false,
            },
            {
                header: "Sale Rate",
                accessorKey: 'sale_rate',
                enableColumnFilter: false,
                cell: (cell: { row: { original: { sale_rate: number } } }) => (
                    <span>{currencyFormat?.currency}{cell.row.original.sale_rate}</span>
                ),
            },
            // {
            //     header: "Total Amount",
            //     accessorKey: 'purchase_rate',
            //     enableColumnFilter: false,
            //     cell: (cell: { row: { original: { amount: number } } }) => (
            //         <span>{currencyFormat?.currency}{cell.row.original.amount}</span>
            //     ),
            // }, need as of now
            {
                header: "Actions",
                cell: (cell: {
                    row: {
                        original: {
                            status: string; id: number, parentProduct: number
                        }
                    }
                }) => (
                    <div className="hstack gap-2">
                        <button
                            id={`editMode-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-info edit-list"
                            onClick={() => {
                                handleEditMode(cell?.row?.original?.id);
                            }}
                        >
                            {viewProductPurchaseData?.status === "Publish" ? <i className="ri-eye-line align-bottom" /> : <i className="ri-pencil-fill align-bottom" />}
                            <ReactTooltip
                                place="bottom"
                                variant="info"
                                content={viewProductPurchaseData?.status === "Publish" ? 'View' : 'Edit'}
                                anchorId={`editMode-${cell?.row?.original?.id}`}
                            />
                        </button>
                        {viewProductPurchaseData?.status !== "Publish" && <button
                            id={`delete-${cell?.row?.original?.id}`}
                            className="btn btn-sm btn-soft-danger remove-list"
                            disabled={viewProductPurchaseData?.status === "Publish"}
                            onClick={() => {
                                onClickDelete(cell?.row?.original?.parentProduct, cell?.row?.original);
                            }}
                        >
                            <i className="ri-delete-bin-5-fill align-bottom" />
                            <ReactTooltip
                                place="bottom"
                                variant="error"
                                content="Delete"
                                anchorId={`delete-${cell?.row?.original?.id}`}
                            />
                        </button>}
                    </div>
                ),
            }
            ], [viewProductPurchaseData?.status])

    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            supplier_id: viewProductPurchaseData?.supplier_id?.id || '',
            bill_no: viewProductPurchaseData?.bill_no || billNumberData,
            bill_date:
                dateFormaterChange(viewProductPurchaseData?.bill_date) || billDate,
            // doc_date: viewProductPurchaseData?.doc_date || docDate,
            // challan_no: viewProductPurchaseData?.challan_no || '',
            city_id: viewProductPurchaseData?.city_id?.id || '',
            state_id: viewProductPurchaseData?.state_id?.id || '',
            gst_type: viewProductPurchaseData?.gst_type || "Unregistered",
            gst_no: viewProductPurchaseData?.gst_no || '',
            // discount_type: viewProductPurchaseData?.discount_type || '',
            bill_discount: viewProductPurchaseData?.bill_discount || 0,
            // round_off: viewProductPurchaseData?.round_off || 0, In this file i have comments the all code of roundOff please allow
            total_mrp: viewProductPurchaseData?.total_mrp || 0,
            net_amount: viewProductPurchaseData?.net_amount || '',
            status: statusOfProductPurchase
        },
        validationSchema: Yup.object().shape({
            supplier_id: Yup.string().required(RequiredField("Supplier name")),
            bill_date: Yup.string().required(RequiredField("Sub category name")),
            gst_type: Yup.string().required('GST Type is required'),
            gst_no: Yup.string().test('requiredForRegistered', 'GST No is required.', function (value) {
                if (this.parent.gst_type === 'Registered') {
                    return !!value;
                }
                return true;
            }),
            bill_no: Yup.string().required(RequiredField("Bill No")),
            // doc_date: Yup.date().required(RequiredField("DOC Date")),
            // challan_no: Yup.string().required(RequiredField("Challan No")),
            state_id: Yup.string().required(RequiredField("State")),
            city_id: Yup.string().required(RequiredField("City")),
            total_mrp: Yup.string().required(RequiredField("Total purchase rate")),
        }),
        onSubmit: (value) => {
            setIsLoading(true);
            const filtersPayload = (obj: any) => {
                const cleanObj: any = {};
                Object.entries(obj).forEach(([key, val]) => {
                    if (val !== '' && val !== null && val !== undefined) {
                        cleanObj[key] = val;
                    }
                });
                return cleanObj;
            };
            if (productPurchaseId) {
                editProductPurchaseEntry(productPurchaseId, filtersPayload(value)).then((response) => {
                    if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        toast.success(response.message);
                        toggleModal();
                        fetchViewProductPurchaseEntry()
                    } else {
                        toast.error(response.message);
                    }
                }).catch((err) => {
                    toast.error(title.somethingWrong);
                    return err;
                }).finally(() => {
                    setIsLoading(false);
                    validation.resetForm();
                });
            } else if (supplierPurchaseId) {
                editProductPurchaseEntry(supplierPurchaseId, filtersPayload(value)).then((response) => {
                    if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        toast.success(response.message);
                        toggleModal();
                        fetchAddEditProductPurchase();
                        validation.resetForm();
                    } else {
                        toast.error(response.message);
                    }
                }).catch((err) => {
                    toast.error(title.somethingWrong);
                    return err;
                }).finally(() => {
                    setIsLoading(false);
                });
            } else {
                supplierProductPurchase({ ...value, net_amount: totalAmount }).then((response) => {
                    if (response?.statusCode && ResponseStatusEnum.SUCCESS.includes(response?.statusCode)) {
                        toast.success(response.message);
                        setSupplierPurchaseId(response?.data);
                        setSupplierProductPurchaseStatus(true);
                        toggleModal();
                        setIsSaveDraftSuccess(true);
                    } else {
                        toast.error(response.message);
                        setIsSaveDraftSuccess(false);
                    }
                }).catch((err) => {
                    toast.error(title.somethingWrong);
                    setIsSaveDraftSuccess(false);
                    return err;
                }).finally(() => {
                    setIsLoading(false);
                });
            }
        }
    });

    const handleGeneratePurchase = () => {
        setStatusOfProductPurchase('Publish');
        validation.submitForm();
    }

    const calculateTotalAmount = () => {
        const { total_mrp, discount_type, bill_discount, }: any = validation.values;
        let discountedAmount = total_mrp;
        if (discount_type === "Amount") {
            discountedAmount -= bill_discount;
        } else if (discount_type === "Percentage") {
            discountedAmount -= (total_mrp * bill_discount / 100);
        }
        const totalWithTax = discountedAmount;
        setTotalAmount(totalWithTax);
    };

    // Call calculateTotalAmount whenever relevant values change
    useEffect(() => {
        calculateTotalAmount();
    }, [validation.values.total_mrp, validation.values.bill_discount]);

    const GSTTypeOptions = [
        { value: 'Registered', label: 'Registered' },
        { value: 'Unregistered', label: 'Unregistered' }
    ];

    const discountTypeOptions = [
        { value: '', label: 'Select' },
        { value: 'Percentage', label: 'Percentage' }
    ];

    const handleClickOfAddProduct = () => {
        if (supplierPurchaseId) {
            navigate(`/addProductPurchase/${supplierPurchaseId}`)
        } else {
            navigate(`/addProductPurchase/${productPurchaseId}`)
        }
    }
    document.title = "Product Purchase"
    return (
        <>
            <div className='page-content'>
                <DeleteModal
                    show={addedProductDeleteModal}
                    onDeleteClick={handleDeleteAddedProduct}
                    onCloseClick={() => setAddedProductDeleteModal(false)}
                />
                {isLoading ?
                    (<Loader />) : (
                        <Container>
                            {generateBarcode && (
                                <Modal
                                    isOpen={pdfModal}
                                    toggle={togglePdfModal}
                                    modalClassName="zoomIn"
                                    centered
                                >
                                    <ModalHeader toggle={togglePdfModal} className="p-3 bg-light p-3">
                                        Product Barcode
                                    </ModalHeader>
                                    <ModalBody className='d-flex justify-content-center'>
                                        <iframe src={`${IMAGE_URL}/${generateBarcode}`} width="100%" height="500px" title="PDF Viewer" />
                                    </ModalBody>
                                </Modal>
                            )}
                            <Row className="mb-4">
                                <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
                                    <h5 className="fw-semibold mb-0">Product Purchase</h5>
                                </div>
                                <div className="col-auto order-2 order-sm-3 ms-auto">
                                    <div className="hstack gap-2">
                                        <button
                                            className="btn btn-success createTask"
                                            type="button"
                                            onClick={handleGeneratePurchase}
                                            disabled={viewProductPurchaseData?.status === "Publish" ||
                                                updatedProductData === undefined || updatedProductData?.length === 0
                                            }
                                        >
                                            GENERATE PURCHASE
                                        </button>

                                        {viewProductPurchaseData?.status === "Publish" && (
                                            spinnerLoading ? (
                                                <Spinner className="me-6" />
                                            ) : (
                                                <button
                                                    className="btn btn-warning createTask"
                                                    type="button"
                                                    onClick={handleProductBarcode}
                                                    disabled={updatedProductData === undefined || updatedProductData?.length === 0}
                                                >
                                                    Print Label
                                                </button>
                                            )
                                        )}

                                        <button
                                            className="btn btn-primary createTask"
                                            type="button"
                                            onClick={() => { navigate('/productPurchaseEntry') }}
                                        >
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </Row>
                            <Card>
                                <CardBody>
                                    {isViewLoading && <LoaderBlur />}
                                    <Form onSubmit={validation.handleSubmit}>
                                        <Row>
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label>Select Supplier Name</Label>
                                                    <Select
                                                        value={supplierNameData?.find((option: OptionType) => option.value === validation.values?.supplier_id)}
                                                        onChange={handleSupplierSelect}
                                                        options={supplierNameData}
                                                        isDisabled={viewProductPurchaseData?.status === "Publish" ? true : false}
                                                    />
                                                    {validation.touched.supplier_id && validation.errors.supplier_id ? (
                                                        <div className="error-message-drop">{validation.errors.supplier_id}</div>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg={3} className="mb-3">
                                                <Label>{supplierLabels.state}</Label>
                                                <Select
                                                    value={listOfStateOptions.find((option: StateOptions) => option.value === validation.values.state_id)}
                                                    onChange={(selectedOption: OptionType) => {
                                                        validation.setFieldValue(
                                                            "state_id",
                                                            selectedOption?.value || ""
                                                        );
                                                    }}
                                                    isDisabled
                                                    options={listOfStateOptions}
                                                    invalid={!!(validation.errors.state_id && validation.touched.state_id)} />
                                                {validation.errors.state_id &&
                                                    validation.touched.state_id ? (
                                                    <div className="error-message-drop">
                                                        {validation.errors.state_id}
                                                    </div>
                                                ) : null}
                                            </Col>

                                            <Col lg={3} className="mb-3">
                                                <Label>{supplierLabels.city}</Label>
                                                <Select
                                                    value={listOfCityOptions?.find((option: CityOptions) => option.value === validation.values.city_id)}
                                                    onChange={(selectedOption: OptionType) => validation.setFieldValue("city_id", selectedOption?.value || "")}
                                                    options={listOfCityOptions}
                                                    isDisabled
                                                    invalid={!!(validation.errors.city_id && validation.touched.city_id)} />
                                                {validation.errors.city_id && validation.touched.city_id ? (
                                                    <div className="error-message-drop">
                                                        {validation.errors.city_id}
                                                    </div>
                                                ) : null}
                                            </Col>

                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label>Bill Date</Label>
                                                    <Flatpickr
                                                        className={`${viewProductPurchaseData?.status === 'Publish' ? 'form-control disabled-input-product-purchase' : 'form-control'}`}
                                                        placeholder='Select Bill Date'
                                                        value={moment(validation.values.bill_date).format("D MMM YYYY")}
                                                        disabled={viewProductPurchaseData?.status === 'Publish'}
                                                        onChange={date => {
                                                            const formattedDate = moment(date[0]).format("YYYY-MM-DD");
                                                            validation.setFieldValue('bill_date', formattedDate);
                                                        }}
                                                        options={{ dateFormat: 'd M Y' }}
                                                    />
                                                    {validation.touched.bill_date && validation.errors.bill_date ? (
                                                        <div className="text-danger">{validation.errors.bill_date}</div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <Label>Bill No.</Label>
                                                <Input
                                                    className={`${viewProductPurchaseData?.status === "Publish" ? 'disabled-text' : ''}`}
                                                    type="number"
                                                    title="bill_no"
                                                    name="bill_no"
                                                    placeholder={PlaceHolderFormat('bill number')}
                                                    value={validation.values.bill_no}
                                                    onBlur={validation.handleBlur}
                                                    readOnly
                                                    disabled={viewProductPurchaseData?.status === "Publish" ? true : false}
                                                    onChange={validation.handleChange}
                                                    invalid={!!(validation.touched.bill_no &&
                                                        validation.errors.bill_no)}
                                                ></Input>
                                                {validation.touched.bill_no &&
                                                    validation.errors.bill_no ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.bill_no}
                                                    </FormFeedback>
                                                ) : null}
                                            </Col>

                                            {/* <Col lg={3}>
                                            <div className="mb-3">
                                                <Label>Challan No.</Label>
                                                <Input
                                                    className={`${viewProductPurchaseData?.status === "Publish" ? 'disabled-text' : ''}`}
                                                    type="number"
                                                    name="challan_no"
                                                    placeholder={PlaceHolderFormat('chalan number')}
                                                    value={validation.values.challan_no}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    disabled={viewProductPurchaseData?.status === "Publish" ? true : false}
                                                    invalid={!!(validation.touched.challan_no && validation.errors.challan_no)} />
                                                {validation.touched.challan_no && validation.errors.challan_no ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.challan_no}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col> */}

                                            {/* <Col lg={3}>
                                            <div className='mb-3'>
                                                <Label>DOC Date</Label>
                                                <Flatpickr
                                                    className={`form-control ${viewProductPurchaseData?.status === "Publish" ? 'disabled-input-product-purchase' : ''}`}
                                                    placeholder='Select Doc Date'
                                                    value={validation.values.doc_date}
                                                    onChange={date => validation.setFieldValue('doc_date', date[0])}
                                                    options={{ dateFormat: 'Y-m-d' }}
                                                    disabled
                                                />
                                                {validation.touched.doc_date && validation.errors.doc_date ? (
                                                    <div className="text-danger">{validation.errors.doc_date}</div>
                                                ) : null}
                                            </div>
                                        </Col> */}

                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label>GST Type</Label>
                                                    <Select
                                                        id="gst_type"
                                                        name="gst_type"
                                                        options={GSTTypeOptions}
                                                        value={GSTTypeOptions?.find(option => option.value === validation.values.gst_type)}
                                                        onChange={(option: OptionType) => validation.setFieldValue('gst_type', option.value)}
                                                        onBlur={validation.handleBlur}
                                                        placeholder="Select GST Type"
                                                        isDisabled={viewProductPurchaseData?.status === "Publish" ? true : false}
                                                    />
                                                    {validation.touched.gst_type && validation.errors.gst_type ? (
                                                        <div className="text-danger">{validation.errors.gst_type}</div>
                                                    ) : null}
                                                </div>
                                            </Col>
                                            {validation.values.gst_type === 'Registered' && (
                                                <Col lg={3}>
                                                    <div className="mb-3">
                                                        <Label>GST No.</Label>
                                                        <Input
                                                            type="text"
                                                            name="gst_no"
                                                            placeholder={PlaceHolderFormat("GST no")}
                                                            value={validation.values.gst_no}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur} />
                                                        {validation.touched.gst_no && validation.errors.gst_no ? (
                                                            <div className="text-danger">{validation.errors.gst_no}</div>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            )}
                                            <hr />
                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label>Total Purchase Rate</Label>
                                                    <Input
                                                        className={`${viewProductPurchaseData?.status === "Publish" ? 'disabled-text' : ''}`}
                                                        type="number"
                                                        pattern="[1-9]"
                                                        title="total_mrp"
                                                        name="total_mrp"
                                                        placeholder={PlaceHolderFormat('total purchase rate')}
                                                        value={viewProductPurchaseData ? viewProductPurchaseData?.total_mrp : 0}
                                                        onBlur={validation.handleBlur}
                                                        min="0"
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value);
                                                            if (newValue >= 0 || e.target.value === "") {
                                                                validation.handleChange(e);
                                                            }
                                                        }}
                                                        disabled
                                                        invalid={!!(validation.touched.total_mrp &&
                                                            validation.errors.total_mrp)}
                                                    ></Input>
                                                    {validation.touched.total_mrp &&
                                                        validation.errors.total_mrp ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.total_mrp}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            <Col lg={3}>
                                                <div className="mb-3">
                                                    <Label>Total Quantity</Label>
                                                    <Input
                                                        className={`${viewProductPurchaseData?.status === "Publish" ? 'disabled-text' : ''}`}
                                                        type="number"
                                                        pattern="[1-9]"
                                                        title="total_quantity"
                                                        name="total_quantity"
                                                        placeholder={PlaceHolderFormat('total quantity')}
                                                        value={viewProductPurchaseData ? viewProductPurchaseData?.total_quantity : 0}
                                                        onBlur={validation.handleBlur}
                                                        min="0"
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value);
                                                            if (newValue >= 0 || e.target.value === "") {
                                                                validation.handleChange(e);
                                                            }
                                                        }}
                                                        disabled
                                                        invalid={!!(validation.touched.total_quantity &&
                                                            validation.errors.total_quantity)}
                                                    ></Input>
                                                    {validation.touched.total_quantity &&
                                                        validation.errors.total_quantity ? (
                                                        <FormFeedback type="invalid">
                                                            {validation.errors.total_quantity}
                                                        </FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>

                                            {/* <Col lg={3}>
                                            <div className="mb-3">
                                                <Label htmlFor="discount_type" className="form-label">Discount Type</Label>
                                                <Select
                                                    id="discount_type"
                                                    name="discount_type"
                                                    options={discountTypeOptions}
                                                    value={discountTypeOptions.find(option => option.value === validation.values.discount_type)}
                                                    onChange={(option: OptionType) => validation.setFieldValue('discount_type', option.value)}
                                                    onBlur={validation.handleBlur}
                                                    isDisabled={viewProductPurchaseData?.status === "Publish" ? true : false}
                                                    placeholder="Select Discount Type" />
                                                {validation.touched.discount_type && validation.errors.discount_type ? (
                                                    <div className="error-message-drop">{validation.errors.discount_type}</div>
                                                ) : null}
                                            </div>
                                        </Col>

                                        {validation.values.discount_type && (
                                            <Col lg={3}>
                                                <Label htmlFor="bill_discount" className="form-label">Bill Discount in {validation.values.discount_type}</Label>
                                                <div className="input-group mb-3">
                                                    {validation.values.discount_type === "Percentage" && <span
                                                        className="input-group-text"
                                                        id="product-Discount-addon"
                                                    >
                                                        %
                                                    </span>}
                                                    <Input
                                                        type="number"
                                                        className={`form-control ${viewProductPurchaseData?.status === "Publish" ? 'disabled-input-product-purchase' : ''}`}
                                                        id="product-Discount-input"
                                                        placeholder="Enter Discount"
                                                        name="bill_discount"
                                                        min="0"
                                                        aria-label="bill_discount"
                                                        aria-describedby="bill-Discount-addon"
                                                        value={validation.values.bill_discount || ""}
                                                        onBlur={validation.handleBlur}
                                                        disabled={viewProductPurchaseData?.status === "Publish" ? true : false}
                                                        onChange={(e) => {
                                                            const newValue = parseFloat(e.target.value);
                                                            if (newValue >= 0 || e.target.value === "") {
                                                                validation.handleChange(e);
                                                            }
                                                        }}
                                                        invalid={!!(validation.errors.bill_discount && validation.touched.bill_discount)} />
                                                    {validation.errors.bill_discount && validation.touched.bill_discount ? (
                                                        <FormFeedback type="invalid">{validation.errors.bill_discount}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </Col>
                                        )}

                                        <Col lg={3}>
                                            <div className="mb-3">
                                                <Label>Net Amount</Label>
                                                <Input
                                                    className={`${viewProductPurchaseData?.status === "Publish" ? 'disabled-text' : ''}`}
                                                    type="number"
                                                    title="net_amount"
                                                    name="net_amount"
                                                    placeholder={PlaceHolderFormat('net amount')}
                                                    value={totalAmount}
                                                    readOnly
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    disabled={viewProductPurchaseData?.status === "Publish" ? true : false}
                                                    invalid={!!(validation.touched.net_amount &&
                                                        validation.errors.net_amount)}
                                                ></Input>
                                                {validation.touched.net_amount &&
                                                    validation.errors.net_amount ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.net_amount}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col> */}

                                            {/* <Col lg={3}>
                                            <div className="mb-3">
                                                <Label>Round Off</Label>
                                                <Input
                                                    className={`${viewProductPurchaseData?.status === "Publish" ? 'disabled-text' : ''}`}
                                                    type="number"
                                                    title="round_off"
                                                    name="round_off"
                                                    placeholder={PlaceHolderFormat('round of')}
                                                    value={validation.values.round_off}
                                                    onBlur={validation.handleBlur}
                                                    onChange={validation.handleChange}
                                                    disabled={viewProductPurchaseData?.status === "Publish" ? true : false}
                                                    invalid={!!(validation.touched.round_off &&
                                                        validation.errors.round_off)}
                                                ></Input>
                                                {validation.touched.round_off &&
                                                    validation.errors.round_off ? (
                                                    <FormFeedback type="invalid">
                                                        {validation.errors.round_off}
                                                    </FormFeedback>
                                                ) : null}
                                            </div>
                                        </Col> */}
                                        </Row>
                                        <div className="hstack m-1 gap-2 justify-content-end">
                                            <button type="submit" className="btn btn-success" disabled={!validation.dirty || viewProductPurchaseData?.status === "Publish"}>{"Save as Draft"}</button>
                                            {supplierProductPurchaseStatus === true ?
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => { handleClickOfAddProduct() }}
                                                >
                                                    {"Add Product "}
                                                </button> :
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    disabled={productPurchaseId === undefined || viewProductPurchaseData?.status === "Publish"}
                                                    onClick={() => { handleClickOfAddProduct() }}
                                                >
                                                    {"Add Product "}
                                                </button>
                                            }
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader>
                                    <h5 className="fw-semibold mb-0">Added Product</h5>
                                </CardHeader>
                                <CardBody>
                                    {updatedProductData && updatedProductData?.length > 0 ? (
                                        <TableContainer
                                            columns={AddProductColumns}
                                            data={updatedProductData}
                                            isGlobalFilter={true}
                                            customPageSize={10}
                                            divClass="table-responsive mb-1"
                                            tableClass="mb-0 align-middle table-border table-wrap"
                                            theadClass="table-light text-muted"
                                            SearchPlaceholder="Search Added Product"
                                            manualPagination={false}
                                            manualSorting={false}
                                        />
                                    ) : (
                                        <div className="py-4 text-center">
                                            <div>
                                                <i className="ri-search-line display-5 text-success"></i>
                                            </div>

                                            <div className="mt-4">
                                                <h5>{notFound.loadingContent}</h5>
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        </Container>
                    )}
            </div>
        </>
    )
}

export default ProductPurchase
