import LoaderBlur from "Components/Common/BlurLoader";
import { listOfColor, listOfSizeRange } from "api/colorAndSize";
import { deleteProduct, deleteProductImage } from "api/productApi";
import { sizeRangeData } from "api/productPurchase";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Card,
  ModalHeader,
} from "reactstrap";
import {
  categoryLabels,
  productPurchase,
  productPurchaseEntry,
  salesLabels,
  sizeRangeLabels,
} from "utils/helpers/constant";
import { RolesEnum } from "utils/helpers/enums";
import dummyImg from "../../assets/images/users/user-dummy-img.jpg";
import { getRole } from "utils/helpers/sessionStores";
import {
  PlaceHolderFormat,
  RequiredField,
} from "utils/helpers/validationRequiredMessages";
import * as Yup from "yup";
import imageCompression from "browser-image-compression";
import { fileUploads } from "api/categoryApi";
import { IMAGE_URL } from "api/apiServices";
import Loader from "Components/Common/loader";

interface SelectOptionSize {
  id: number;
  size_range: string;
}

interface SelectOption {
  value: number;
  label: string;
}

interface OptionType {
  label: string;
  value: number;
}
interface Props {
  purchaseRateAUD: any;
  onUpdate?: any;
  updateData: any;
  setUpdateData?: any;
  isOpen: boolean;
  onFormSubmit: any;
  purchaseRate: undefined | null | number;
  status: any;
  fetchViewProduct: any;
  setListOfColorIdData: React.Dispatch<React.SetStateAction<any[]>>;
  setFileName: React.Dispatch<React.SetStateAction<{ [key: number]: any[] }>>;
  varientImages: any;
}
interface ColorOption {
  value: string;
  label: string;
}
interface ColorGroup {
  color: ColorOption;
  quantities: { [key: string]: { quantity: number; id: number } };
  image: Image[];
}
interface Image {
  path: string;
  name: string;
}
interface Variant {
  id: number;
  color: string;
  size: string;
  quantity: number;
}
interface SizeData {
  id: number;
  size_range: string;
  from_size: string;
  to_size: string;
  sizes: string[];
}
interface ColorData {
  label: string;
  value: string;
}

const DynamicColumnWithProduct = ({
  purchaseRateAUD,
  onUpdate,
  updateData,
  purchaseRate,
  isOpen,
  onFormSubmit,
  status,
  setListOfColorIdData,
  fetchViewProduct,
  setUpdateData,
  setFileName,
  varientImages,
}: Props) => {
  const { productId }: any = useParams();
  const [sizesOptions, setSizesOptions] = useState<
    { value: number; label: string }[]
  >([]);
  const [sizesData, setSizesData] = useState<SizeData>();
  const [selectedSize, setSelectedSize] = useState<number>();
  const [additionalSelectedFiles, setAdditionalSelectedFiles] = useState<any>(
    {}
  );

  const [splitSizeData, setSplitSizeData] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isImgLoading, setIsImgLoading] = useState(false);
  const [listOfColorData, setListOfColorData] = useState();
  const [fromAndToSizeData, setFromAndToSizeData] = useState();
  const [fileNames, setFileNames] = useState<string[]>([]);
  let uploadedImages: any[] = [];
  const [isGalleryUpload, setIsGalleryUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [varientImage, setVarientImage] = useState(varientImages);
  const [openModalIndex, setOpenModalIndex] = useState<number | null>(null);
  const [rowSelectedFiles, setRowSelectedFiles] = useState<{
    [key: number]: any[];
  }>(varientImages || {});
  let img: any = [];
  const [colorId, setColorId] = useState<any>();
  const role = getRole();

  const handleModalOpen = (index: number) => {
    setOpenModalIndex(index);
  };

  const handleAcceptedFiles = async (index: number, acceptedFiles: File[]) => {
    setIsImgLoading(true);
    const newSelectedFiles = await Promise.all(
      acceptedFiles.map(async (file) => {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1024,
          useWebWorker: true,
        };
        try {
          const compressedFile = await imageCompression(file, options);
          const newFile = new File([compressedFile], file.name, {
            type: compressedFile.type,
            lastModified: compressedFile.lastModified,
          });
          const formData = new FormData();
          formData.append("files", newFile);
          setIsGalleryUpload(true);
          const response = await fileUploads(formData);
          const uploadedFile = response?.data?.[0];
          img.push(uploadedFile);
          if (uploadedFile) {
            validation.setFieldValue(`colorList[${index}].image`, img);
          }
          return {
            preview: URL.createObjectURL(newFile),
            name: uploadedFile, // This can be modified based on the response structure
            formattedSize: formatBytes(file.size),
          };
        } catch (error) {
          toast.error("Image compression or upload failed");
          return null;
        } finally {
          setIsImgLoading(false);
          setIsGalleryUpload(false);
        }
      })
    );
    const validFiles = newSelectedFiles.filter((file) => file !== null);

    // setRowSelectedFiles((prevFiles) => {
    //   const updatedFiles = {
    //     ...prevFiles,
    //     [index]: [...(prevFiles[index] || []), ...validFiles], // Ensure correct index
    //   };
    //   return updatedFiles;
    // });
    setRowSelectedFiles((prevFiles) => {
      const updatedFiles = {
        ...prevFiles,
        [index]: [...(prevFiles[index] || []), ...newSelectedFiles],
      };
      return updatedFiles;
    });
  };
  // setFileName(updatedFiles);
  function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const fetchSize = () => {
    setIsLoading(true);
    listOfSizeRange({})
      .then((response) => {
        setSizesOptions(
          response?.data?.map((size: SelectOptionSize) => ({
            value: size.id,
            label: size.size_range,
          }))
        );
        setFromAndToSizeData(response?.data);
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchColor = () => {
    setIsLoading(true);
    listOfColor({})
      .then((response) => {
        setListOfColorData(
          response?.data?.map((color: { id: number; color_name: string }) => ({
            value: color.color_name,
            label: color.color_name,
          }))
        );
        setListOfColorIdData(
          response?.data?.map((color: { id: number; color_name: string }) => ({
            value: color.id,
            label: color.color_name,
          }))
        );
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (updateData?.size_range_id?.id) {
      const preFilledValue = updateData?.size_range_id?.id;

      setSelectedSize(preFilledValue);

      const preFilledOption = sizesOptions?.find(
        (option: SelectOption) => option?.value === preFilledValue
      );

      if (preFilledOption) {
        handleSizeChange(preFilledOption);
      }
    }
  }, [updateData, sizesOptions]);

  useEffect(() => {
    if (updateData) {
      const updatedColorList = updateData?.product_variant?.reduce(
        (acc: any, variant: any) => {
          const { color, size, quantity } = variant;

          // Check if color already exists in acc
          const existingColor = acc.find((item: any) => item.color === color);

          if (existingColor) {
            // Add size and quantity to the existing color
            existingColor.quantities[size] = { quantity };
          } else {
            // Create a new color entry
            acc.push({
              color: color,
              quantities: { [size]: { quantity } },
              image: updateData?.product_images?.filter(
                (image: any) => image.color_id === variant.color_id
              ),
            });
          }
          return acc;
        },
        []
      );

      setInitialValues((prevValues) => ({
        ...prevValues,
        colorList: updatedColorList,
      }));
    }
  }, [updateData]);

  // As of now i have commented code because of its giving the empty fields but i need in future.
  // useEffect(() => {
  //     if (updateData) {
  //         // Prefill saleRate and mrp with values from updateData if available
  //         const saleRate = updateData?.product_variant?.[0]?.sale_rate || '';
  //         const mrp = updateData?.product_variant?.[0]?.mrp || '';
  //         setInitialValues((prevValues: any) => ({
  //             ...prevValues,
  //             saleRate: !isNaN(parseFloat(saleRate)) ? parseFloat(saleRate) : purchaseRateAUD,
  //             mrp: !isNaN(parseFloat(mrp)) ? parseFloat(mrp) : purchaseRateAUD,
  //         }));
  //     } else {
  //         // If there's no updateData, prefills with purchaseRateAUD
  //         setInitialValues((prevValues: any) => ({
  //             ...prevValues,
  //             saleRate: !isNaN(parseFloat(purchaseRateAUD)) ? parseFloat(purchaseRateAUD) : '',
  //             mrp: !isNaN(parseFloat(purchaseRateAUD)) ? parseFloat(purchaseRateAUD) : '',
  //         }));
  //     }
  // }, [updateData, purchaseRateAUD]);

  const handleSizeChange = (selectedOption: SelectOption) => {
    setIsLoading(true);
    setSelectedSize(selectedOption?.value);

    const selectedSizeOption = sizesOptions?.find(
      (option: { value: number }) => option.value === selectedOption.value
    );

    if (selectedSizeOption) {
      sizeRangeData(selectedSizeOption.value)
        .then((response) => {
          setSizesData(response?.data);
          setFromAndToSizeData(response?.data);
        })
        .catch((err) => {
          return err;
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      setSizesData(undefined);
    }
  };

  useEffect(() => {
    Promise.all([fetchSize(), fetchColor()]);
  }, []);

  const validateColor = (
    selectedColors: string[],
    index: number,
    value: string
  ) => {
    // Get colors in the same row except the current one
    const colorsInRow = validation.values.colorList
      ?.filter((_: any, i: number) => i !== index)
      ?.map((colorGroup: any) => colorGroup.color);

    // Check if the selected color is unique within the row
    return !colorsInRow?.includes(value);
  };

  // Update the validation schema for colorList
  Yup.object().shape({
    size: Yup.string().required(RequiredField(sizeRangeLabels.size)),
    saleRate: Yup.string().required(RequiredField(productPurchase.saleRate)),
    mrp: Yup.string().required(RequiredField(productPurchase?.mrp)),
    colorList: Yup.array().of(
      Yup.object().shape({
        color: Yup.string()
          .required(RequiredField(productPurchase?.color))
          .test(
            "uniqueColor",
            "Please select a different color in this row.",
            function (value) {
              return validateColor(
                this.parent.colorList?.map((color: ColorGroup) => color.color),
                this.parent.indexOf(this),
                value
              );
            }
          ),
        quantities: Yup.object().test(
          "atLeastOneQuantity",
          RequiredField(productPurchase?.atLeastOneQuantity),
          (quantities) => {
            return Object.values(quantities).some(
              (quantity: any) => quantity.quantity > 0
            );
          }
        ),
      })
    ),
  });
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [initialValues, setInitialValues] = useState({
    size: "",
    fromSize: "",
    toSize: "",
    purchaseRate: purchaseRateAUD || purchaseRate,
    saleRate:
      updateData?.product_variant?.[0]?.sale_rate ||
      (purchaseRateAUD && purchaseRateAUD) ||
      "",
    mrp: updateData?.product_variant?.[0]?.mrp || purchaseRateAUD || "",
    colorList: [
      {
        color: "",
        quantities: {},
        image: updateData && (additionalSelectedFiles as Image[]),
      },
    ],
  });
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      size: Yup.string().required(RequiredField(sizeRangeLabels.size)),
      saleRate: Yup.string().required(RequiredField(productPurchase.saleRate)),
      mrp: Yup.string().required(RequiredField(productPurchase?.mrp)),
      colorList: Yup.array().of(
        Yup.object().shape({
          color: Yup.string().required(RequiredField(productPurchase?.color)),
          quantities: Yup.object().test(
            "atLeastOneQuantity",
            RequiredField(productPurchase?.atLeastOneQuantity),
            (quantities) => {
              return Object.values(quantities).some(
                (quantity: any) => quantity.quantity > 0
              );
            }
          ),
        })
      ),
    }),
    onSubmit: (value) => {
      // Handle form submission here
    },
  });

  useEffect(() => {
    if (updateData && !initialDataLoaded) {
      const colorGroups: { [key: string]: ColorGroup } = {};
      updateData?.product_variant?.forEach((item: Variant) => {
        const { id, color, size, quantity, image }: any = item;

        if (!colorGroups[color]) {
          colorGroups[color] = {
            color: color,
            image: image,
            quantities: {},
            // Object to store quantities for each size
          };
        }
        colorGroups[color].quantities[size] = { quantity, id }; // Include id if variant exists
      });

      // Merge pre-filled quantities into colorGroups if available
      if (updateData?.colorList) {
        updateData?.colorList?.forEach((colorItem: any) => {
          const { color, quantities } = colorItem?.color;

          if (colorGroups[color]) {
            colorGroups[color].quantities = {
              ...colorGroups[color].quantities,
              ...quantities,
            };
          }
        });
      }

      const groupedVariants: any = Object?.values(colorGroups);

      setInitialValues({
        size: updateData?.size_range_id?.id || "",
        fromSize: "",
        toSize: "",
        purchaseRate: purchaseRateAUD || purchaseRate,
        saleRate: updateData?.product_variant?.[0]?.sale_rate || "", //here i do the hard code because the BE give that and that is never change.
        mrp: updateData?.product_variant?.[0]?.mrp || "",
        colorList: groupedVariants,
      });

      setInitialDataLoaded(true);
    }
  }, [updateData, purchaseRateAUD, initialDataLoaded, purchaseRate]);

  useEffect(() => {
    validation.setFieldValue("mrp", validation.values.saleRate);
  }, [validation.values.saleRate]);

  useEffect(() => {
    if (onFormSubmit === true) {
      validation.handleSubmit();
    }
  }, [onFormSubmit === true]);

  useEffect(() => {
    onUpdate(validation.values);
  }, [validation.values, onUpdate]);
  const handleAddReplicaRow = () => {
    validation.handleSubmit();
    if (validation?.isValid) {
      const lastColor =
        validation.values.colorList[validation.values.colorList?.length - 1]; //here i have add the logic id its first row then not hide just empty that row.
      const newQuantities: { [key: string]: { quantity: number; id: string } } =
        {};

      // Copy quantities from the last row without the id field
      Object.keys(lastColor.quantities).forEach((size) => {
        newQuantities[size] = {
          quantity: lastColor.quantities[size].quantity,
          id: "",
        };
      });

      const newColor = { color: "", quantities: newQuantities };

      validation.setFieldValue("colorList", [
        ...validation.values.colorList,
        newColor,
      ]);
    }
  };

  const handleQuantityChange = (
    colorIndex: number,
    size: string,
    quantity: number
  ) => {
    if (quantity >= 0 || isNaN(quantity)) {
      // Copy the colorList from Formik's values
      const newColorList = [...validation.values.colorList];

      // Get the color to update based on the color index
      const colorToUpdate = newColorList[colorIndex];

      // Copy the quantities object for that color
      const updatedQuantities = { ...colorToUpdate.quantities };

      // Update the quantity for the specific size without creating a new row
      updatedQuantities[size] = {
        ...(updatedQuantities[size] || {}), // Keep any existing size properties
        quantity, // Update or add the quantity for the size
      };

      // Update the color row with the new quantities object
      const updatedColor = { ...colorToUpdate, quantities: updatedQuantities };

      // Replace the old color entry with the updated one
      newColorList[colorIndex] = updatedColor;

      // Set the updated colorList back to Formik's field
      validation.setFieldValue("colorList", newColorList);
    }
  };

  const handleDeleteVariant = async (colorIndex: number) => {
    const colorToDelete = validation.values.colorList[colorIndex];
    const colorName = colorToDelete.color;
    if (updateData) {
      let filteredImages = updateData?.product_images?.filter((item: any) => {
        return (
          item?.color_id?.color_name.toUpperCase() === colorName.toUpperCase()
        );
      });
      const deletePayload = {
        productImageIds: filteredImages.map((item: any) => {
          return item.id;
        }),
      };
      setIsLoading(true);
      const payload = {
        product_id: parseInt(productId),
        color: colorName,
      };
      deleteProduct(payload)
        .then((res) => {
          const newColorList = [...validation.values.colorList];
          newColorList.splice(colorIndex, 1);
          validation.setFieldValue("colorList", newColorList);
          deleteProductImage(deletePayload);
          toast.success(res?.message);
        })
        .catch((err) => {
          toast.error(err?.message);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      // If updateData is not present, delete the variant locally
      const newColorList = [...validation.values.colorList];
      if (colorIndex === 0) {
        // If it's the first row, empty the quantities of that row
        newColorList[colorIndex].quantities = {};
        newColorList[colorIndex].color = "";
      } else {
        // If it's not the first row, remove the entire row
        newColorList.splice(colorIndex, 1);
      }
      validation.setFieldValue("colorList", newColorList);
    }
  };
  const [selectedColors, setSelectedColors] = useState<string[]>([]);
  const handleColorChange = (index: number, selectedColor: string) => {
    // Check if the selected color is already present in colorList
    const isColorSelectedInList = validation.values.colorList.some(
      (colorGroup: any, i: number) => {
        return (
          i !== index &&
          colorGroup.color.toLowerCase() === selectedColor.toLowerCase()
        );
      }
    );

    if (isColorSelectedInList) {
      // Set validation error message if the color is already selected in the list
      validation.setFieldError(
        `colorList[${index}].color`,
        "This color has already been selected in a previous row."
      );
    } else {
      validation.setFieldValue(`colorList[${index}].color`, selectedColor);
      validation.setFieldTouched(`colorList[${index}].color`, true, false); // Mark color field as touched
      validation.validateField(`colorList[${index}].color`); // Trigger validation for the color field

      // Add the selected color to the list of selected colors
      setSelectedColors((prevSelectedColors) => [
        ...prevSelectedColors,
        selectedColor,
      ]);
    }
  };

  const getOptionsWithSelected = (
    options: any,
    selectedValue: string
  ): SelectOption[] => {
    if (
      selectedValue &&
      !options?.some((option: any) => option?.value === selectedValue)
    ) {
      return [...options, { value: selectedValue, label: selectedValue }];
    }
    return options;
  };

  const productSizeSelected = getOptionsWithSelected(
    sizesOptions,
    validation.values.size
  );

  function handleRadioChange(index: number) {
    const selectedFileName = selectedFiles[index]?.name;
    setVarientImage(selectedFileName);
  }

  // const removeFile = async (file: any | undefined, indexToRemove: number) => {
  //     if (file) {
  //         try {
  //             await deleteProductImage(file?.id);
  //         } catch (error) {
  //             return error;
  //         }
  //     }
  //     setSelectedFiles((prevFiles: []) => prevFiles?.filter((file: string, index: number) => index !== indexToRemove));
  // };

  const removeFile = async (file: any, index: number, fileIndex: number) => {
    if (file) {
      try {
        // Make an API call to delete the file if needed
        let files = [];
        files.push(file?.id);
        const payload = {
          productImageIds: files,
        };
        await deleteProductImage(payload);
      } catch (error) {
        toast.error("Failed to delete the image");
        return;
      }
    }

    // Update rowSelectedFiles state
    setRowSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles[index]];
      updatedFiles.splice(fileIndex, 1);
      return {
        ...prevFiles,
        [index]: updatedFiles,
      };
    });
  };
  const removeAdditionalFile = async (
    file: any,
    fileIndex: number,
    color: string
  ) => {
    if (file) {
      try {
        // Make an API call to delete the file if needed
        let files = [];
        files.push(file?.id);
        const payload = {
          productImageIds: files,
        };
        await deleteProductImage(payload).then((resp: any) => {
          if (resp?.status === "Success") {
            // Remove from additionalSelectedFiles
            setAdditionalSelectedFiles((prevFiles: any) => {
              const colorGroup = prevFiles[color] || [];
              const updatedColorGroup = colorGroup.filter(
                (_: any, idx: number) => idx !== fileIndex
              );

              // Ensure that if there are no remaining files for this color, remove the color entry
              return {
                ...prevFiles,
                [color]: updatedColorGroup.length
                  ? updatedColorGroup
                  : undefined,
              };
            });
            setUpdateData((prevData: any) => ({
              ...prevData,
              product_images: prevData.product_images.filter(
                (image: any) => image.id !== file.id
              ),
            }));
          }
        });
      } catch (error) {
        toast.error("Failed to delete the image");
        return error;
      }
    }
  };

  // const removeAdditionalFile = async (
  //   file: any,
  //   fileIndex: number,
  //   color: string
  // ) => {
  //   if (file) {
  //     try {
  //       await deleteProductImage(file?.id).then((resp: any) => {
  //         if (resp?.status === "Success") {
  //           setAdditionalSelectedFiles((prevFiles: any) => {
  //             const colorGroup = prevFiles[color] || [];
  //             const updatedColorGroup = colorGroup.filter(
  //               (_: any, idx: number) => idx !== fileIndex
  //             );
  //             return {
  //               ...prevFiles,
  //               [color]: updatedColorGroup,
  //             };
  //           });
  //         }
  //       });
  //     } catch (error) {
  //       toast.error("Failed to delete the image");
  //       return error;
  //     }
  //   }
  // };

  useEffect(() => {
    if (updateData?.product_images && validation.values.colorList) {
      validation.values.colorList.forEach((color: ColorGroup) => {
        updateAdditionalImagesByColor(
          updateData?.product_images,
          String(color.color)
        );
      });
    }
  }, [updateData?.product_images, validation.values.colorList]);

  const updateAdditionalImagesByColor = (items: Array<any>, color: string) => {
    setAdditionalSelectedFiles((prevFiles: any) => {
      const filteredImages = items
        ?.filter((item) => item?.color_id?.color_name === color)
        ?.map((item) => ({
          id: item?.id,
          url: `${IMAGE_URL}${item?.image}`,
          name: item?.image,
        }));

      // If no new images, return the same state
      const existingImages = prevFiles[color] || [];
      const existingImageIds = new Set(
        existingImages.map((img: any) => img.id)
      );

      // Only add new images that are not already in the state
      const newImages = filteredImages.filter(
        (img) => !existingImageIds.has(img.id)
      );

      // Prevent unnecessary state update if no new images
      if (newImages.length === 0) return prevFiles;

      const updatedFiles = {
        ...prevFiles,
        [color]: [...existingImages, ...newImages],
      };

      return updatedFiles;
    });
  };

  return (
    <div>
      <Row>
        <Col lg={4}>
          <div className="mb-3">
            <Label>{productPurchase?.selectSize}</Label>
            <Select
              className={`${
                validation.touched.size &&
                validation.errors.size &&
                "is-invalid"
              }`}
              value={sizesOptions?.find(
                (option: SelectOption) =>
                  option.value === validation.values.size
              )}
              onChange={(selectedOption: SelectOption) => {
                setSplitSizeData(selectedOption?.label);
                validation.setFieldValue("size", selectedOption?.value || "");
                handleSizeChange(selectedOption);
              }}
              options={sizesOptions}
              isDisabled={
                role === RolesEnum?.STAFF ||
                (updateData?.product_status ===
                  productPurchaseEntry?.onlineStatus &&
                  status !== "Online")
              }
            />
            {validation.touched.size && validation.errors.size && (
              <div className="error-message-drop">{validation.errors.size}</div>
            )}
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label>{productPurchase?.fromSize}</Label>
            <Input
              type="text"
              name="fromSize"
              id="fromSize"
              placeholder={PlaceHolderFormat(productPurchase?.fromSize)}
              value={
                updateData
                  ? updateData?.size_range_id?.from_size
                  : sizesData?.from_size
              }
              disabled={role === RolesEnum?.STAFF || status !== "Online"}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div className="mb-3">
            <Label>{productPurchase?.toSize}</Label>
            <Input
              type="text"
              name="toSize"
              id="toSize"
              placeholder={PlaceHolderFormat(productPurchase?.toSize)}
              value={
                updateData
                  ? updateData?.size_range_id?.to_size
                  : sizesData?.to_size
              }
              disabled={role === RolesEnum?.STAFF || status !== "Online"}
            />
          </div>
        </Col>
      </Row>
      {role !== RolesEnum?.STAFF && (
        <Row>
          {(isOpen === true || updateData !== undefined) && (
            <Col md={4}>
              <Label for="purchaseRate">{productPurchase?.purchaseRate}</Label>
              <Input
                type="number"
                name="purchaseRate"
                id="purchaseRate"
                placeholder={PlaceHolderFormat(productPurchase?.purchaseRate)}
                value={purchaseRateAUD || purchaseRate}
                disabled={status === "Online"}
              />
            </Col>
          )}
          <Col md={4}>
            <Label for="saleRate">{productPurchase?.saleRate}</Label>
            <Input
              type="number"
              name="saleRate"
              placeholder={PlaceHolderFormat(productPurchase?.saleRate)}
              id="saleRate"
              value={validation.values.saleRate}
              onChange={validation?.handleChange}
              onBlur={validation?.handleBlur}
              disabled={
                updateData?.product_status ===
                  productPurchaseEntry?.onlineStatus && status === "Online"
              }
              invalid={
                !!(validation.touched.saleRate && validation.errors.saleRate)
              }
            />
            {validation.touched.saleRate && validation.errors.saleRate && (
              <div className="error-message-drop">
                {validation.errors.saleRate}
              </div>
            )}
          </Col>
          <Col md={4}>
            <Label for="mrp">
              {productPurchase?.mrp} ({productPurchase.comparativeRate})
            </Label>
            <Input
              type="number"
              name="mrp"
              placeholder={PlaceHolderFormat(productPurchase?.mrp)}
              id="mrp"
              value={validation?.values?.mrp}
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              disabled={
                updateData?.product_status ===
                  productPurchaseEntry?.onlineStatus && status === "Online"
              }
              invalid={!!(validation.touched.mrp && validation.errors.mrp)}
            />
            {validation.touched.mrp && validation.errors.mrp && (
              <div className="error-message-drop">{validation.errors.mrp}</div>
            )}
          </Col>
        </Row>
      )}
      {isLoading ? (
        <LoaderBlur />
      ) : (
        <>
          {selectedSize !== undefined && (
            <div className="my-3">
              <h5> {productPurchase?.sizeList}</h5>
              <Table responsive className="mb-0 align-middle table-border">
                <thead className="table-light text-muted table-wrap">
                  <tr>
                    <th>{productPurchase?.delete}</th>
                    <th>{productPurchase?.color}</th>
                    <th>{productPurchase?.productImage}</th>
                    {sizesData?.sizes?.map((size, index) => (
                      <th key={size}>{size}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {validation.values.colorList?.map(
                    (color: ColorGroup, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            <Button
                              color="danger"
                              disabled={
                                role === RolesEnum?.STAFF ||
                                (updateData?.product_status ===
                                  productPurchaseEntry?.onlineStatus &&
                                  status !== "Online")
                              }
                              onClick={() => {
                                handleDeleteVariant(index);
                              }}
                            >
                              {" "}
                              <i className="ri-delete-bin-5-fill align-bottom" />
                            </Button>
                          </td>
                          <td>
                            <CreatableSelect
                              value={{ value: color.color, label: color.color }}
                              // onChange={(newValue: any) => validation.setFieldValue(`colorList[${index}].color`, newValue.value)}  need this line for future
                              onChange={(newValue: any) =>
                                handleColorChange(index, newValue.value)
                              }
                              options={listOfColorData}
                              placeholder="Select..."
                              styles={{
                                control: (provided) => ({
                                  ...provided,
                                  width: "150px",
                                }),
                              }}
                              isDisabled={
                                role === RolesEnum?.STAFF ||
                                (updateData?.product_status ===
                                  productPurchaseEntry?.onlineStatus &&
                                  status !== "Online")
                              }
                              menuPortalTarget={document.body}
                            />
                            {validation.touched.colorList &&
                              validation.errors.colorList &&
                              validation.errors.colorList[index]?.color && (
                                <div className="error-message-drop">
                                  {validation.errors.colorList[index].color}
                                </div>
                              )}
                          </td>
                          <td>
                            <i
                              className="display-6 text-muted ri-upload-cloud-2-fill"
                              onClick={() => handleModalOpen(index)}
                            ></i>
                            <Modal
                              isOpen={openModalIndex === index}
                              modalClassName="zoomIn"
                              centered
                            >
                              <ModalHeader
                                toggle={() => setOpenModalIndex(null)}
                              >
                                Color : {String(color.color)}
                              </ModalHeader>
                              <ModalBody className="border-top modal-height">
                                {isImgLoading ? (
                                  <Loader color="primary" />
                                ) : (
                                  <Dropzone
                                    onDrop={(acceptedFiles) => {
                                      handleAcceptedFiles(index, acceptedFiles);
                                    }}
                                    // disabled={viewProductData?.product_status === productPurchaseEntry?.onlineStatus && status !== "Online"}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div
                                        className="dropzone dz-clickable dropZoneMobile"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="dz-message needsclick">
                                          <div className="mb-3 mt-3">
                                            <i className="display-6 text-muted ri-upload-cloud-2-fill" />
                                          </div>
                                          <h5>
                                            Drop files here or click to upload.
                                          </h5>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                )}
                                <div
                                  className="list-unstyled mb-0"
                                  id="file-previews"
                                >
                                  {rowSelectedFiles[index]?.map(
                                    (file: any, fileIndex: number) => {
                                      return (
                                        <Card
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                          key={fileIndex}
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <img
                                                  data-dz-thumbnail=""
                                                  height="80"
                                                  className="avatar-sm rounded bg-light pl-4"
                                                  alt={file.name}
                                                  // src={file}
                                                  src={`${IMAGE_URL}${file.name}`}
                                                  onError={(e: any) => {
                                                    e.target.src = dummyImg;
                                                  }}
                                                />
                                              </Col>
                                              <Col>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {file.name}
                                                </Link>
                                              </Col>
                                              <Col className="d-flex justify-content-end">
                                                <button
                                                  type="submit"
                                                  className="btn btn-danger"
                                                  onClick={(e) => {
                                                    e.preventDefault();
                                                    removeFile(
                                                      file,
                                                      index,
                                                      fileIndex
                                                    );
                                                  }}
                                                >
                                                  <i className="ri-delete-bin-5-fill align-bottom d-flex justify-content-end" />
                                                </button>
                                              </Col>
                                            </Row>
                                          </div>
                                        </Card>
                                      );
                                    }
                                  )}
                                </div>
                                <div
                                  key={index}
                                  className="list-unstyled mb-0"
                                  id="additional-file-previews"
                                >
                                  {additionalSelectedFiles[
                                    String(color.color)
                                  ]?.map((file: any, fileIndex: number) => (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={fileIndex}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light pl-4"
                                              alt={file.name}
                                              src={file.url}
                                              onError={(e: any) => {
                                                e.target.src = dummyImg;
                                              }}
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {file.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {file.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                          <Col className="d-flex justify-content-end">
                                            <button
                                              type="submit"
                                              className="btn btn-danger"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                removeAdditionalFile(
                                                  file,
                                                  Number(fileIndex),
                                                  String(color.color)
                                                );
                                              }}
                                            >
                                              <i className="ri-delete-bin-5-fill align-bottom d-flex justify-content-end" />
                                            </button>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  ))}
                                </div>
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="secondary"
                                  className="btn btn-ghost-success"
                                  onClick={() => setOpenModalIndex(null)}
                                >
                                  {"Cancel"}
                                </Button>
                              </ModalFooter>
                            </Modal>
                          </td>
                          {sizesData?.sizes?.map((size, sizeIndex) => (
                            <td key={sizeIndex}>
                              <Input
                                className="dynamicColumnWidth"
                                type="number"
                                value={
                                  (color.quantities[size] &&
                                    color.quantities[size].quantity) ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleQuantityChange(
                                    index,
                                    size,
                                    parseInt(e.target.value)
                                  )
                                }
                                disabled={
                                  role === RolesEnum?.STAFF ||
                                  (updateData?.product_status ===
                                    productPurchaseEntry?.onlineStatus &&
                                    status !== "Online")
                                }
                              />
                              {sizeIndex === 0 &&
                                validation.touched.colorList &&
                                validation.errors.colorList &&
                                validation.errors.colorList[index]
                                  ?.quantities &&
                                validation.errors.colorList[index] && (
                                  <div className="error-message-drop">
                                    {
                                      validation.errors.colorList[index]
                                        ?.quantities
                                    }
                                  </div>
                                )}
                            </td>
                          ))}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </>
      )}
      <Row>
        <div className="d-flex justify-content-end align-items-center w-100">
          <Button
            className="btn-soft-success m-2"
            disabled={
              role === RolesEnum?.STAFF ||
              (updateData?.product_status ===
                productPurchaseEntry?.onlineStatus &&
                status !== "Online")
            }
            onClick={handleAddReplicaRow}
          >
            {productPurchase?.addReplica}
          </Button>
        </div>
      </Row>
    </div>
  );
};

export default DynamicColumnWithProduct;
