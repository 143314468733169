import { useFormik } from "formik";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  categoryLabels,
  financialYear,
  shippingRateLabels,
} from "utils/helpers/constant";
import { PlaceHolderFormat } from "utils/helpers/validationRequiredMessages";
import { editShippingCharge, viewShippingCharge } from "api/shippingRateApi";
import { toast } from "react-toastify";

interface viewShippingData {
  id: number;
  shipping_charge: string;
  is_free_shipping: boolean;
}

const ShippingRate = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [viewShippingData, setViewShippingData] = useState<viewShippingData>();
  const [isChecked, setIsChecked] = useState<boolean>();

  const fetchViewShippingCharge = () => {
    let payload = {
      condition: {
        id: 1,
      },
      selectionCriteria: ["id", "name"],
      sortOrder: {
        id: 1,
      },
      pageSize: 5,
      pageNumber: 1,
      searchBar: "",
    };
    viewShippingCharge(payload)
      .then((resp) => {
        setViewShippingData(resp.data[0]);
        setIsChecked(resp.data[0]?.is_free_shipping)
      })
      .catch((err) => {
        return err;
      });
  };

  useEffect(() => {
    fetchViewShippingCharge();
  }, []);

  const handleCancel = () => {
    navigate(-1);
  };

  document.title = shippingRateLabels?.title;

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      shipping_charge: viewShippingData?.shipping_charge || "",
      is_free_shipping: false,
    },
    onSubmit: (values) => {
      let data = {} as { is_free_shipping: boolean; shipping_charge?: string };
      if (values.is_free_shipping) {
        data.is_free_shipping = values.is_free_shipping;
      } else {
        data = {
          is_free_shipping: values.is_free_shipping,
          shipping_charge: values.shipping_charge,
        };
      }
      if (!viewShippingData) {
        return;
      }
      setIsLoading(true);
      editShippingCharge(viewShippingData.id, data)
        .then((resp) => {
          if (resp.statusCode === 202) {
            fetchViewShippingCharge();
            toast.success(resp.message);
          }
        })
        .catch((error) => {
          return error;
        });
    },
  });

  if (validation.values.is_free_shipping) {
    validation.values.shipping_charge = "";
  }

  return (
    <div className="page-content">
      <Container>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row className="mb-3">
            <div className="col-sm order-3 order-sm-2 mt-3 mt-sm-0">
              <h5 className="fw-semibold mb-0">{shippingRateLabels?.title}</h5>
            </div>
            <div className="col-auto order-2 order-sm-3 ms-auto">
              <div className="hstack gap-2">
                <button
                  className="btn btn-primary createTask"
                  type="button"
                  onClick={handleCancel}
                >
                  {financialYear?.back}
                </button>
              </div>
            </div>
          </Row>
          <Row>
            <Col lg={5}>
              <Card>
                <CardBody>
                  <Label className="">{shippingRateLabels?.enterRate}</Label>
                  <Input
                    type="text"
                    name="shipping_charge"
                    className="m-1 mb-3"
                    title={shippingRateLabels.title}
                    disabled={validation.values.is_free_shipping}
                    placeholder={PlaceHolderFormat(shippingRateLabels.title)}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.shipping_charge || ""}
                    invalid={
                      !!(
                        validation.touched.shipping_charge &&
                        validation.errors.shipping_charge
                      )
                    }
                  />
                  <div className="text-end d-flex justify-content-between">
                    <div
                      className="form-check form-switch form-switch-md mx-1"
                      dir="ltr"
                    >
                      <Input
                        type="checkbox"
                        name="is_free_shipping"
                        className="form-check-input"
                        id="is_free_shipping"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        checked={isChecked || validation.values.is_free_shipping}
                        invalid={
                          !!(
                            validation.touched.is_free_shipping &&
                            validation.errors.is_free_shipping
                          )
                        }
                      />
                      <Label
                        className="form-check-label"
                        for="is_free_shipping"
                      >
                        {shippingRateLabels.freeShipping}
                      </Label>
                    </div>

                    <Button
                      color="primary"
                      className="w-sm me-2"
                      disabled={!validation.dirty}
                    >
                      {categoryLabels?.submit}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default ShippingRate;
