export const validationMessages = {
  required: (fieldName: string) =>
    `${
      fieldName.charAt(0).toUpperCase() + fieldName.slice(1).toLowerCase()
    } is required`,
  format: (fieldName: string) => ` ${fieldName} should be in correct format.`,
  passwordLength: (fieldName: string, minLength: number) =>
    `${fieldName} must be at least ${minLength} characters`,
  passwordComplexity: (fieldName: string) =>
    `${fieldName} must contain at least one uppercase letter, one lowercase letter, one number, and one special character`,
  passwordsMatch: (fieldName: string) =>
    `${fieldName} and confirm password should be same.`,
  phoneNumber: (fieldName: string) =>
    `Invalid ${fieldName.toLowerCase()} format`,
  notSameAsField: (fieldName: string, comparedField: string) =>
    `${fieldName} must be different from ${comparedField}`,
  maxLength: (fieldName: string, maxLength: number) =>
    `${fieldName} must be at most ${maxLength} characters`,
};

export const title = {
  signIn: "Login",
  somethingWrong: "Something went wrong",
};

export const loginLabels = {
  enter: "Enter",
  email: "Email",
  password: "Password",
  welcomeBack: "Welcome Back !",
};

export const categoryLabels = {
  category: "Category",
  addCategory: "Add",
  editCategory: "Edit",
  categoryName: "Category Name",
  categoryAccessKey: "name",
  accessKeyCategory: "category_name",
  accessKeyDescription: "category_description",
  description: "Description",
  descriptionAccessKey: "description",
  image: "Image",
  imageAccessKey: "images",
  actions: "Actions",
  uploadFile: "Upload file",
  nameOfCategory: "Category name",
  create: "Create",
  update: "Update",
  cancel: "Cancel",
  submit: "Submit",
  categoryImage: "Image",
  discount: "discount",
  discountAmount: "Discount amount",
  discountPercentage: "Discount percentage",
  discount_in_percentage: "Discount(%)",
  discount_in_amount: "Discount in amount",
  amountAccess: "discount_in_amount",
  percentageAccess: "discount_in_percentage",
  minimumDiscount: "Discount percentage must be greater than or equal to 0.",
  maximumDiscount: "Discount percentage must be less than or equal to 100.",
  minimumDiscountOfOrder: "Discount percentage must be greater than 0.",
  sizeChart: "Size Chart",
  viewImage: "View Image",
  addDiscount: "Please add the discount.",
  validSKU: "Please enter the valid SKU.",
  validDiscount: "Please add the valid discount",
};

export const notFound = {
  dataNotFound: "Sorry! No Result Found",
  nullData: "---",
  notAvailable: "NA",
  loadingContent: "",
};

export const subCategoryLabels = {
  categoryNameKey: "categoryName",
  subCategory: "Sub-category",
  subCategoryNameOfKey: "Sub-Category Name",
  subCategoryAccessKey: "subCategory_name",
  select: "Select",
};

export const hsnLabels = {
  hsn: "HSN",
  hsnCode: "HSN Code",
  hsnCodeAccessorKey: "code",
};

export const supplierLabels = {
  title: "Supplier",
  supplierName: "Supplier Name",
  supplierNameAccessKey: "supplier_name",
  emailAccesskey: "email",
  contactNo: "Contact No.",
  contactNoAccessKey: "contact_no",
  address: "Address",
  contactMatches: "Contact number must be exactly 10 digits",
  zipCodeMatches: "Zip-code must be exactly 4 digits",
  emailMatches: "Invalid email address",
  supplier: "Supplier name",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  addressLine2AccessKey: "address_line_2",
  addressLine1AccessKey: "address_line_1",
  zipCode: "Postal-code",
  zipCodeAccessKey: "zip_code",
  zipCodeName: "Postal Code",
  county: "County",
  state: "State",
  city: "Suburb",
};

export const purchaseProductLabels = {
  title: "Purchase Product",
  productName: "Product Name",
  productNameAccessKey: "product_name",
  quantity: "Quantity",
  quantityAccessKey: "quantity",
  totalPrice: "Total Price",
  totalPriceAccessKey: "total_price",
  startDateEndDate: "Start Date - End date",
  orderStatus: "Order Status",
  orderType: "Order Type",
  filterByName: "Filter By Role",
};

export const customerLabels = {
  title: "Customer",
  customerContactNoAccessKey: "customerContactNo",
  customerNameAccessKey: "customerName",
  emailAccessKey: "email",
  orderCountAccessKey: "orderCount",
  productCountAccessKey: "productCount",
  totalAmountAccessKey: "totalAmount",
  customerContactNo: "Customer Contact Number",
  customerName: "Customer Name",
  email: "Email",
  orderCount: "No of Order",
  productCount: "No of Product",
  totalAmount: "Total Amount",
  contactNumber: "Contact no",
  registerDate: "Registration Date",
  billDate: "Bill Date",
  billDateAccessKey: "billDate",
};

export const salesLabels = {
  title: "Sales",
  totalOrdersAccessKey: "totalOrders",
  totalProductsAccessKey: "totalProducts",
  totalAmountAccessKey: "totalAmount",
  customerNameAccessKey: "customerName",
  totalOrders: "Total Order",
  totalProducts: "Total Products",
  totalAmount: "Total Amount",
  customerName: "Customer Name",
  orderDate: "Order Date",
  orderDateAccessKey: "orderDate",
};

export const supplierReportLabels = {
  title: "Supplier",
  supplierNameAccessKey: "supplierName",
  productNameAccessKey: "productName",
  totalQuantityAccessKey: "quantity",
  totalAmountAccessKey: "totalAmount",
  taxAccessKey: "tax",
  supplierName: "Supplier Name",
  productName: "Product Name",
  quantity: "Quantity",
  tax: "Tax",
  totalAmount: "Total Amount",
};

export const staffLabels = {
  title: "Staff",
  addStaff: "Add",
  name: "Name",
  email: "Email",
  contact: "Contact No",
  address: "Address",
  addressLine1: "Address Line 1",
  addressLine2: "Address Line 2",
  country: "Country",
  state: "State",
  city: "Suburb",
  zipcode: "Zipcode",
  password: "Password",
  confirmPassword: "Confirm Password",
  accessKeyName: "staff_name",
  accessKeyEmail: "email",
  accessKeyContact: "contact_no",
  addressKeyAddress: "address_line_2",
};

export const productPurchaseEntry = {
  productName: "Product Name",
  productNameAccessKey: "product_name",
  productDescription: "Product Description",
  mrp: "mrp",
  convertedRate: 50,
  draftStatus: "Draft",
  onlineStatus: "Online",
};

export const addSalesOrderReturn = {
  Amount: "Amount",
  Percentage: "Percentage",
  sku: "SKU",
  barcode: "barcode",
  Product: "Product",
  product_name: "product_name",
  Color: "Color",
  color: "color",
  Size: "Size",
  size: "size",
  Quantity: "Quantity",
  quantity: "quantity",
  SaleRate: "Sale Rate",
  sale_rate: "sale_rate",
  Discounttype: "Discount type",
  discount_type: "discount_type",
  Discount: "Discount",
  discount: "discount",
  actions: "Actions",
  docNo: "doc no",
  docDate: "Doc date",
  roundOff: "round off",
  netPayable: "net payable",
  netAmount: "net amount",
  cashAmount: "cash amount",
  cardAmount: "card amount",
  selectCustomer: "Select Customer",
  mobileNo: "mobile no",
  mrp: "mrp",
  CustomerName: "Customer Name",
  CustomerEmail: "Customer Email",
  CustomerMobile: "Customer Mobile No",
  cancel: "Cancel",
  submit: "Submit",
  save: "Save",
  draft: "Draft",
  price: "price",
  DocNo: "Doc No.",
  docTime: "Doc Time",
  totalQuantity: "Total Quantity",
  saveAsDraft: "Save as Draft",
  discountType: "discountType",
  addCustomerDetail: "Add Customer Details",
  mrpAmount: "MRP Amount",
  card: "Card :",
  cash: "Cash :",
  paymentMode: "Payment Mode",
  mobileNumber: "Mobile Number",
  addNew: "Add New",
  customerCode: "Customer Code",
  enter: "Enter",
  discountIn: "Discount In",
  billNumber: "bill number",
  publish: "Publish",
  discountAmount: "Discounted Sale Rate",
  discountAmountAccessor: "discounted_sale_rate",
};

export const salesReturnList = {
  docNO: "Doc No.",
  doc_no: "doc_no",
  salesReturnEntry: "Sales Return Entry",
  addSales: "+ Add",
  docDate: "Doc Date",
  doc_date: "doc_date",
  totalProduct: "Total Product",
  total_product: "total_products",
  TotalQuantity: "Total Quantity",
  total_quantity: "total_quantity",
  gstTax: "GST/TAX",
  tax: "tax",
  totalAmount: "Total Amount",
  total_amount: "total_amount",
  actions: "Actions",
  draft: "Draft",
  edit: "Edit",
  view: "View",
};

export const orderStatusOptions = [
  { label: "All", value: "" },
  { label: "Pending", value: "Pending" },
  { label: "Returns", value: "Returned" },
  { label: "Delivered", value: "Delivered" },
];

export const orderPaymentOptions = [
  { label: "All", value: "" },
  { label: "Online", value: "Online-Payment" },
  { label: "COD", value: "Cash" },
];

export const currencyFormat = {
  currency: "A$",
};

export const graphLabels = {
  onlinePayments: "Online Payments",
  cash: "Cash",
};

export const billLabels = {
  billTitle: "Try Me Fashion Hub",
  address: `Shop 1/22 Parkes Street Harris Park NSW 2150, Contact:0450456791, ABN
  80 707408 117, Email: Info.trymefashionhub@gmail.com`,
  taxInvoice: "TAX INVOICE",
  no: "No:",
  date: "Date:",
  code: "Code",
  desc: "Desc",
  mrp: "MRP",
  qty: "QTY",
  rate: "Rate",
  amount: "Amount",
  quantity: "Qty:",
  netAmount: "Net Amount:",
  youHaveSaved: "You have saved",
  gstRate: "GST Rate",
  taxable: "Taxable",
  cgst: "CGST",
  sgst: "SGST",
  refundAmount: "Refund Amount :",
  recAmt: "RecAmt:",
  payBack: "Pay Back =",
  eoe: "E.&.O.E.",
  forTryMe: "For try me fashion hub",
  termsAndCondition: "Terms and condition",
  noExchangeRefund: "NO EXCHANGE NO REFUND",
  thankyou: "THANKYOU AND VISIT AGAIN !!!",
  hsn: "HSN",
  disc: "Disc",
  gst: "GST %",
  tenPercent: "10%",
  viewBill: "View Bill",
  shippingCharge: "Shipping Charge",
  subTotal: "Sub Total",
};

export const sizeRangeLabels = {
  sizeRange: "Size-range",
  title: "title",
  size: "Size",
  sizeRangeKey: "size_range",
  sizes: "Sizes",
};

export const productPurchase = {
  addVariants: "Add Variants",
  selectSize: "Select Size",
  fromSize: "From Size",
  toSize: "To Size",
  purchaseRate: "Purchase Rate",
  saleRate: "Sale Rate",
  mrp: "MRP",
  comparativeRate: "Compare At Rate",
  sizeList: "Size List",
  delete: "Delete",
  color: "Color",
  productImage: "Product Image",
  addReplica: "Add Replica",
  replicaMessage: "Please select at least one color before adding a replica.",
  productCode: "Product Code",
  productName: "Product Name",
  productDescription: "Product Description",
  publish: "Publish",
  status: "Status",
  productCategories: "Product Categories",
  select: "Select",
  productCategory: "category",
  productSubCategory: "sub-category",
  inrMark: "INR",
  audMark: "AUD",
  shippingRate: "Shipping Rate",
  productConvertedRate: "Converted Rate",
  totalQuantity: "Total Quantity",
  productTags: "Product Tags",
  selectedTags: "Selected Tags",
  searchBar: "Search via Product Code",
  atLeastOneQuantity: "At least one quantity",
};

export const metaLabels = {
  metaTitle: "Meta Title",
  metaKeywords: "Meta Keywords",
  metaDescription: "Meta Description",
};

export const orderLabels = {
  pageSizeOfOrders: 8,
};

export const stockDetails = {
  sizeRange: "Size Range",
  cost: "Purchase Rate",
  billNumber: "Bill No.",
  billNumberAccessKey: "bill_no",
  billDate: "Bill Date",
  billDateAccessKey: "bill_date",
  subCategoryDescription: "Sub Category Description",
  subCatDesc: "sub_category_des",
};

export const financialYear = {
  title: "Financial Year",
  selectFinancialYear: "Select the Financial Year",
  back: "Back",
};
export const dashboardFilter = [
  { label: "All", value: "All" },
  { label: "Online", value: "Online" },
  { label: "Offline", value: "Offline" },
];

export const sectionLabels = {
  goodMorning: "Good Morning",
  goodEvening: "Good Evening",
  goodAfternoon: "Good Afternoon",
  admin: "Admin",
  seller: "Seller",
};

export const stockDetailsAccessKey = {
  title: "Stock Details",
  skuKey: "sku",
  productCodeKey: "productCode",
  productNameKey: "productName",
  productDescKey: "productDesc",
  colorNameKey: "colorName",
  sizeRangeKey: "sizeRange",
  categoryNameKey: "categoryName",
  subcategoryNameKey: "subcategoryName",
  saleRateKey: "saleRate",
  hsnCodeKey: "hsnCode",
  purchaseRateKey: "purchaseRate",
  supplierNameKey: "supplierName",
  billNoKey: "billNo",
  billDateKey: "billDate",
};

export const supportLabels = {
  title: "Support",
  fullName: "Full Name",
  email: "Email",
  phoneNo: "Phone No",
  message: "Message",
  remarks: "Remarks",
  contact_name: "contact_name",
  contact_email: "contact_email",
  message_accessor: "message",
  contact_no: "contact_no",
  reason: "reason",
  status: "Rejected",
};

export const resetPasswordLabels = {
  resetPassword: "Reset Password",
  password: "Password",
  confirmPassword: "Confirm Password",
  changePassword: "Change Password",
  submit: "Submit",
  passwordKey: "password",
  confirmPasswordKey: "confirmPassword",
  newPassword: "New Password",
  currentPassword: "Current Password",
};

export const addressLabels = {
  title: "Customer Information",
  customer_name: "Customer Name:",
  viewAddress: "View Address",
};

export const shippingRateLabels = {
  title: "Shipping Rate",
  enterRate: "Enter shipping rate",
  back: "Back",
  shippingRate: 'shipping rate',
  rateToggle: 'rate toggle',
  freeShipping: 'Free Shipping'
};
