import React, { useEffect, useState, useMemo } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  Button,
} from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import moment from "moment";
import TableContainer from "Components/Common/TableContainer";
import { deleteOrder, listOfOrders } from "api/ordersApi";
import {
  currencyFormat,
  notFound,
  orderLabels,
  title,
} from "utils/helpers/constant";
import DeleteModal from "Components/Common/DeleteModal";
import { ResponseStatusEnum } from "utils/helpers/enums";
import { toast } from "react-toastify";
import { OrderGlobalFilter } from "Components/Common/GlobalSearchFilter";
import LoaderBlur from "Components/Common/BlurLoader";
import { string } from "yup";
import { getFinancialYear } from "utils/helpers/sessionStores";

interface GlobalFilter {
  condition: {
    orderStatus: string;
    orderPayment: string;
  };
}

const EcommerceOrders = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("2");
  const [isLoading, setIsLoading] = useState(false);
  const selectLayoutState = (state: any) => state.Ecommerce;
  const selectLayoutProperties = createSelector(selectLayoutState, (ecom) => ({
    orders: ecom?.orders,
    isOrderSuccess: ecom?.isOrderSuccess,
    error: ecom?.error,
  }));
  const { orders } = useSelector(selectLayoutProperties);
  const [orderList, setOrderList] = useState<any>([]);
  const [order, setOrder] = useState<any>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalNumberOfRows, setTotalNumberOfRows] = useState(0);
  const [searchOrdersData, setSearchOrderData] = useState([]);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedOrderId, setSelectedOrderId] = useState<number>();
  const [searchValue, setSearchValue] = useState("");
  const [globalSearchFilter, setGlobalFiltersData] = useState<GlobalFilter>();
  const [customPageSize, setCustomPageSize] = useState(8);
  const financialYear = getFinancialYear();
  const [filters, setFilters] = useState<any>();
  const [columnName, setColumnName] = useState("order_id");
  const [sortOrders, setSortOrders] = useState("DESC");

  const handleFetchSorting = (page: number, id: string, order: string) => {
    setCurrentPage(page);
    setColumnName(id);
    setSortOrders(order);
  };

  const onClickDelete = (orderId: any) => {
    setSelectedOrderId(orderId);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    deleteOrder(selectedOrderId)
      .then((response) => {
        if (
          response.statusCode === response?.statusCode &&
          ResponseStatusEnum.SUCCESS.includes(response?.statusCode)
        ) {
          fetchListOfOrder();
          toast.success(response.message);
          setDeleteModal(false);
        } else {
          toast.error(response.message);
        }
      })
      .catch((err) => {
        toast.error(title.somethingWrong);
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setOrderList(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders)) setOrderList(orders);
  }, [orders]);

  const toggleTab = (tab: any, type: any) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      let filteredOrders = orders;
      if (type !== "Offline Store") {
        filteredOrders = order?.filter((order: any) => order.status === type);
      }
      setOrderList(filteredOrders);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    setOrder(orders);
  }, [orders]);

  useEffect(() => {
    if (!isEmpty(orders)) {
      setOrder(orders);
      setIsEdit(false);
    }
  }, [orders]);

  const [ordersData, setOrdersData] = useState([]);
  const [isFirstCall, setIsFirstCall] = useState(true);

  const fetchListOfOrder = () => {
    let sortOrder;
    if (isFirstCall) {
      sortOrder = [
        { field: "bill_date", order: sortOrders },
        { field: columnName, order: sortOrders },
      ];
    } else {
      sortOrder = [{ field: columnName, order: sortOrders }];
    }

    let condition: any = {
      pageNumber: currentPage,
      pageSize: customPageSize,
      sortOrder: sortOrder,
      financialYear: financialYear,
    };

    // Include filters if present
    if (globalSearchFilter) {
      condition.condition = {
        order_status: globalSearchFilter?.condition?.orderStatus,
        payment_option: globalSearchFilter?.condition?.orderPayment,
      };
    }

    if (activeTab === "2") {
      condition.orderBy = ["Seller", "Staff"];
    } else if (activeTab === "3") {
      condition.orderBy = ["Customer", "Guest"];
    }

    setIsLoading(true);
    listOfOrders(condition)
      .then((response) => {
        setTotalRecords(response.data.totalRecordsCount);
        setTotalPages(response?.data?.totalPages);
        setTotalNumberOfRows(response?.data?.numberOfRows);
        setOrdersData(response?.data?.listOfOrderData);
        if (isFirstCall) {
          setIsFirstCall(false);
        }

        if (searchValue) {
          fetchSearchData();
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchSearchData = () => {
    let condition: any = {
      pageNumber: currentPage,
      pageSize: customPageSize,
      sortOrder: [
        { field: "bill_date", order: sortOrders },
        { field: columnName, order: sortOrders },
      ],
      searchBar: searchValue,
      financialYear: financialYear,
    };

    // Include filters if present
    if (globalSearchFilter) {
      condition.condition = {
        order_status: globalSearchFilter?.condition?.orderStatus,
        payment_option: globalSearchFilter?.condition?.orderPayment,
      };
    }

    if (activeTab === "2") {
      condition.orderBy = ["Seller", "Staff"];
    } else if (activeTab === "3") {
      condition.orderBy = ["Customer", "Guest"];
    }

    setIsLoading(true);
    listOfOrders(condition)
      .then((response) => {
        setTotalRecords(response.data.totalRecordsCount);
        setTotalPages(response?.data?.totalPages);
        setTotalNumberOfRows(response?.data?.numberOfRows);
        setSearchOrderData(response?.data?.listOfOrderData);
      })
      .catch((err) => {
        setSearchOrderData([]);
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFetchData = (page: number) => {
    setCurrentPage(page);
  };

  const fetchFiltersData = (filters: any) => {
    setGlobalFiltersData(filters);
    let payload = {};
    if (activeTab === "2") {
      payload = {
        condition: {
          order_status: filters?.condition?.orderStatus,
          payment_option: filters?.condition?.orderPayment,
        },
        orderBy: ["Seller", "Staff"],
        sortOrder: [
          { field: "bill_date", order: sortOrders },
          { field: columnName, order: sortOrders },
        ],
        pageNumber: currentPage,
        pageSize: customPageSize,
        financialYear: financialYear,
      };
    } else if (activeTab === "3") {
      payload = {
        condition: {
          order_status: filters?.condition?.orderStatus,
          payment_option: filters?.condition?.orderPayment,
        },
        orderBy: ["Customer", "Guest"],
        sortOrder: [
          { field: "bill_date", order: sortOrders },
          { field: columnName, order: sortOrders },
        ],
        pageNumber: currentPage,
        pageSize: customPageSize,
        financialYear: financialYear,
      };
    } else {
      payload = {
        condition: {
          order_status: filters?.condition?.orderStatus,
          payment_option: filters?.condition?.orderPayment,
        },
        pageNumber: currentPage,
        pageSize: customPageSize,
        sortOrder: [
          { field: "bill_date", order: sortOrders },
          { field: columnName, order: sortOrders },
        ],
        financialYear: financialYear,
      };
    }
    setIsLoading(true);
    listOfOrders(payload)
      .then((response) => {
        setOrdersData(response?.data?.listOfOrderData);
        setTotalRecords(response.data.totalRecordsCount);
        setTotalPages(response?.data?.totalPages);
        setTotalNumberOfRows(response?.data?.numberOfRows);
        setSearchOrderData(response?.data?.listOfOrderData);
      })
      .catch((err) => {
        setSearchOrderData([]);
        return err;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleFiltersSubmit = (payload: any) => {
    fetchFiltersData(payload);
  };

  const handleRefresh = () => {
    setFilters({
      orderStatus: null,
      orderPayment: null,
    });
    handleFiltersSubmit({
      condition: {
        orderStatus: null,
        orderPayment: null,
      },
      pageSize: 8,
      sortOrder: [
        { field: "bill_date", order: sortOrders },
        { field: columnName, order: sortOrders },
      ],
      pageNumber: 1,
    });
  };

  const handleUpdateOrder = (orderId: number) => {
    navigate(`/orders/updateSalesOrder/${orderId}`);
  };

  const handleSearchValueChange = (value: string) => {
    if (value !== searchValue) {
      setSearchValue(value);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (searchValue) {
      fetchSearchData();
    } else {
      fetchListOfOrder();
    }
  }, [
    activeTab,
    currentPage,
    searchValue,
    customPageSize,
    columnName,
    sortOrders,
  ]);

  useEffect(() => {
    if (searchValue === "") {
      setCurrentPage(1);
    }
  }, [searchValue]);

  const handleViewOrder = (orderId: number) => {
    navigate(`/orders/order-details/${orderId}`);
  };

  // Columns for activeTabs
  const columns2 = useMemo(
    () => [
      {
        header: "Order No.",
        accessorKey: "order_id",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <> {cell.getValue() ?? notFound?.nullData}</>;
        },
      },
      {
        header: "Bill No.",
        accessorKey: "bill_no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <> {cell.getValue() ?? notFound?.nullData}</>;
        },
      },
      {
        header: "Order Date",
        accessorKey: "bill_date",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {handleValidDate(cell.getValue())},
            <small className="text-muted">
              {" "}
              {cell?.row?.original?.bill_time}
            </small>
          </>
        ),
      },
      {
        header: "Customer",
        accessorKey: "customer_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <> {cell.getValue() ? cell?.getValue() : notFound?.nullData}</>
          );
        },
      },
      {
        header: "Total Product",
        accessorKey: "total_products",
        enableColumnFilter: false,
      },
      {
        header: "Total Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
        cell: (cell: { row: { original: { total_amount: number } } }) => (
          <span>
            {currencyFormat?.currency}
            {cell.row.original.total_amount}
          </span>
        ),
      },
      {
        header: "Total Quantity",
        accessorKey: "total_quantity",
        enableColumnFilter: false,
      },
      {
        header: "Payment Method",
        accessorKey: "payment_method",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "order_save_status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case "Draft":
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  {" "}
                  {cell.getValue()}{" "}
                </span>
              );
            case "Publish":
              return (
                <span className="badge text-uppercase bg-success-subtle text-success">
                  {" "}
                  {cell.getValue()}{" "}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  {" "}
                  {cell.getValue()}{" "}
                </span>
              );
          }
        },
      },
      {
        header: "Action",
        enableSorting: false,
        cell: (cellProps: any) => {
          const { bill_no } = cellProps?.row?.original;
          const isDisabled = !bill_no;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {!bill_no ? (
                <li className="list-inline-item">
                  <button
                    className="btn text-primary button-disabled"
                    onClick={() => {
                      handleViewOrder(cellProps?.row?.original?.order_id);
                    }}
                    disabled={!isDisabled}
                  >
                    <i className="ri-eye-fill fs-16"></i>
                  </button>
                </li>
              ) : (
                <li className="list-inline-item edit">
                  <button
                    className="btn text-primary button-disabled"
                    onClick={() => {
                      handleUpdateOrder(cellProps?.row?.original?.order_id);
                    }}
                    disabled={isDisabled}
                  >
                    {cellProps?.row?.original?.order_save_status ===
                    "Publish" ? (
                      <i className="ri-eye-fill fs-16"></i>
                    ) : (
                      <i className="ri-pencil-fill fs-16"></i>
                    )}
                  </button>
                </li>
              )}
              {/* Dont need the order delete so hide that content */}
              {/* <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original?.order_id;
                    onClickDelete(orderData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ],
    []
  );

  const columns3 = useMemo(
    () => [
      {
        header: "Order No.",
        accessorKey: "order_id",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <> {cell.getValue() ?? notFound?.nullData}</>;
        },
      },
      {
        header: "Bill No.",
        accessorKey: "bill_no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <> {cell.getValue() ?? notFound?.nullData}</>;
        },
      },
      {
        header: "Order Date",
        accessorKey: "bill_date",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {handleValidDate(cell.row.original.bill_date)}
            <small className="text-muted">
              {" "}
              {cell?.row?.original?.bill_time}
            </small>
          </>
        ),
      },
      {
        header: "Customer",
        accessorKey: "customer_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <> {cell.getValue() ? cell.getValue() : notFound?.nullData}</>;
        },
      },
      {
        header: "Total Product",
        accessorKey: "total_products",
        enableColumnFilter: false,
      },
      {
        header: "Total Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
        cell: (cell: { row: { original: { total_amount: number } } }) => (
          <span>
            {currencyFormat?.currency}
            {cell.row.original.total_amount}
          </span>
        ),
      },
      {
        header: "Total Quantity",
        accessorKey: "total_quantity",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <> {cell.getValue() ?? notFound?.nullData}</>;
        },
      },
      {
        header: "Payment Method",
        accessorKey: "payment_method",
        enableColumnFilter: false,
      },
      {
        header: "Delivered Status",
        accessorKey: "delivery_status",
        enableColumnFilter: false,
        cell: (cell: any) => {
          switch (cell.getValue()) {
            case "Delivered":
              return (
                <span className="badge text-uppercase bg-success-subtle text-success">
                  {" "}
                  {cell.getValue()}{" "}
                </span>
              );
            case "Pending":
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  {" "}
                  {cell.getValue()}{" "}
                </span>
              );
            case "Returned":
              return (
                <span className="badge text-uppercase bg-danger-subtle text-danger">
                  {" "}
                  {cell.getValue()}{" "}
                </span>
              );
            case "OutOfStock":
              return (
                <span className="badge text-uppercase bg-secondary-subtle text-secondary">
                  {" "}
                  {cell.getValue()}{" "}
                </span>
              );
            case "Failed":
              return (
                <span className="badge text-uppercase bg-info-subtle text-info">
                  {" "}
                  {cell.getValue()}{" "}
                </span>
              );
            default:
              return (
                <span className="badge text-uppercase bg-warning-subtle text-warning">
                  {" "}
                  {cell.getValue()}{" "}
                </span>
              );
          }
        },
      },
      {
        header: "Action",
        enableSorting: false,
        cell: (cellProps: any) => {
          const { bill_no } = cellProps?.row?.original;
          const isDisabled = !bill_no;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {!bill_no ? (
                <li className="list-inline-item">
                  <button
                    className="btn text-primary button-disabled"
                    onClick={() => {
                      handleViewOrder(cellProps?.row?.original?.order_id);
                    }}
                    disabled={!isDisabled}
                  >
                    <i className="ri-eye-fill fs-16"></i>
                  </button>
                </li>
              ) : (
                <li className="list-inline-item edit">
                  <button
                    className="btn text-primary button-disabled"
                    onClick={() => {
                      handleUpdateOrder(cellProps?.row?.original?.order_id);
                    }}
                    disabled={isDisabled}
                  >
                    <i className="ri-pencil-fill fs-16"></i>
                  </button>
                </li>
              )}
              {/* now dont need the order delete */}
              {/* <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original?.order_id;
                    onClickDelete(orderData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ],
    []
  );

  const columns1 = useMemo(() => {
    const dynamicColumns = [
      {
        header: "Order No.",
        accessorKey: "order_id",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <> {cell.getValue() ?? notFound?.nullData}</>;
        },
      },
      {
        header: "Bill No.",
        accessorKey: "bill_no",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <> {cell.getValue() ?? notFound?.nullData}</>;
        },
      },
      {
        header: "Order Date",
        accessorKey: "bill_date",
        enableColumnFilter: false,
        cell: (cell: any) => (
          <>
            {handleValidDate(cell.row.original.bill_date)}
            <small className="text-muted">
              {" "}
              {cell?.row?.original?.bill_time}
            </small>
          </>
        ),
      },
      {
        header: "Customer",
        accessorKey: "customer_name",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return (
            <> {cell.getValue() ? cell?.getValue() : notFound?.nullData}</>
          );
        },
      },
      {
        header: "Total Product",
        accessorKey: "total_products",
        enableColumnFilter: false,
      },
      {
        header: "Total Amount",
        accessorKey: "total_amount",
        enableColumnFilter: false,
        cell: (cell: { row: { original: { total_amount: number } } }) => (
          <span>
            {currencyFormat?.currency}
            {cell.row.original.total_amount}
          </span>
        ),
      },
      {
        header: "Total Quantity",
        accessorKey: "total_quantity",
        enableColumnFilter: false,
        cell: (cell: any) => {
          return <> {cell.getValue() ?? notFound?.nullData}</>;
        },
      },
      {
        header: "Payment Method",
        accessorKey: "payment_method",
        enableColumnFilter: false,
      },
      {
        header: "Status",
        accessorKey: "",
        enableColumnFilter: false,
        cell: (cell: any) => {
          const billNo = cell?.row?.original?.bill_no;
          const offlineOrders = cell?.row?.original?.order_save_status;
          const onlineOrders = cell?.row?.original?.delivery_status;

          if (billNo == null) {
            switch (onlineOrders) {
              case "Delivered":
                return (
                  <span className="badge text-uppercase bg-success-subtle text-success">
                    {" "}
                    {onlineOrders}{" "}
                  </span>
                );
              case "Pending":
                return (
                  <span className="badge text-uppercase bg-danger-subtle text-danger">
                    {" "}
                    {onlineOrders}{" "}
                  </span>
                );
              case "Returned":
                return (
                  <span className="badge text-uppercase bg-danger-subtle text-danger">
                    {" "}
                    {onlineOrders}{" "}
                  </span>
                );
              case "OutOfStock":
                return (
                  <span className="badge text-uppercase bg-secondary-subtle text-secondary">
                    {" "}
                    {onlineOrders}{" "}
                  </span>
                );
              // case "Pickups":
              //     return <span className="badge text-uppercase bg-info-subtle text-info"> {onlineOrders} </span>;
              case "Failed":
                return (
                  <span className="badge text-uppercase bg-info-subtle text-info">
                    {" "}
                    {onlineOrders}{" "}
                  </span>
                );
              default:
                return (
                  <span className="badge text-uppercase bg-warning-subtle text-warning">
                    {" "}
                    {onlineOrders}{" "}
                  </span>
                );
            }
          } else {
            switch (offlineOrders) {
              case "Draft":
                return (
                  <span className="badge text-uppercase bg-warning-subtle text-warning">
                    {" "}
                    {offlineOrders}{" "}
                  </span>
                );
              case "Publish":
                return (
                  <span className="badge text-uppercase bg-info-subtle text-info">
                    {" "}
                    {offlineOrders}{" "}
                  </span>
                );
              default:
                return (
                  <span className="badge text-uppercase bg-warning-subtle text-warning">
                    {" "}
                    {offlineOrders}{" "}
                  </span>
                );
            }
          }
        },
      },
      {
        header: "Action",
        enableSorting: false,
        cell: (cellProps: any) => {
          const { bill_no } = cellProps?.row?.original;
          const isDisabled = !bill_no;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              {!bill_no ? (
                <li className="list-inline-item">
                  <button
                    className="btn text-primary button-disabled"
                    onClick={() => {
                      handleViewOrder(cellProps?.row?.original?.order_id);
                    }}
                    disabled={!isDisabled}
                  >
                    <i className="ri-eye-fill fs-16"></i>
                  </button>
                </li>
              ) : (
                <li className="list-inline-item edit">
                  <button
                    className="btn text-primary button-disabled"
                    onClick={() => {
                      handleUpdateOrder(cellProps?.row?.original?.order_id);
                    }}
                    disabled={isDisabled}
                  >
                    {cellProps?.row?.original?.order_save_status ===
                    "Publish" ? (
                      <i className="ri-eye-fill align-bottom" />
                    ) : (
                      <i className="ri-pencil-fill align-bottom" />
                    )}
                  </button>
                </li>
              )}
              {/* as of now dont need the order delete */}
              {/* <li className="list-inline-item">
                <Link
                  to="#"
                  className="text-danger d-inline-block remove-item-btn"
                  onClick={() => {
                    const orderData = cellProps.row.original?.order_id;
                    onClickDelete(orderData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-16"></i>
                </Link>
              </li> */}
            </ul>
          );
        },
      },
    ];
    return dynamicColumns;
  }, []);

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidDateAndTime = (date: any) => {
    const parsedDate = moment(date);
    const formattedDate = parsedDate.format("DD MMM Y");
    const formattedTime = parsedDate.format("HH:mm:ss");
    return (
      <div>
        {formattedDate}
        <small className="text-muted"> {formattedTime}</small>
      </div>
    );
  };

  const handleValidTime = (time: any) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime =
      moment(getTime, "hh:mm").format("hh:mm") + " " + meridiem;
    return updateTime;
  };

  document.title = "Orders";
  return (
    <div className="page-content">
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card id="orderList">
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="col-sm">
                    <h5 className="card-title mb-0">Order History</h5>
                  </div>
                  <div className="col-sm-auto">
                    <div className="d-flex gap-1 flex-wrap">
                      <Button
                        className="btn btn-success add-btn"
                        onClick={() => navigate("/orders/createSalesOrder")}
                      >
                        + Create Order
                      </Button>
                    </div>
                  </div>
                </Row>
              </CardHeader>
              {isLoading && <LoaderBlur />}
              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggleTab("1", "all");
                          setOrdersData([]);
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All Orders
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggleTab("2", "Offline Store");
                          setOrdersData([]);
                        }}
                        href="#"
                      >
                        <i className="ri-checkbox-circle-line me-1 align-bottom"></i>{" "}
                        Offline Store
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "3" })}
                        onClick={() => {
                          toggleTab("3", "Online Shop");
                          setOrdersData([]);
                        }}
                        href="#"
                      >
                        <i className="ri-arrow-left-right-fill me-1 align-bottom"></i>{" "}
                        Online Shop
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <CardBody className="border border-dashed border-end-0 border-start-0">
                    <form>
                      <Row>
                        <Col sm={5}></Col>
                        <OrderGlobalFilter
                          onSubmitFilters={fetchFiltersData}
                          filters={filters}
                          onRefresh={handleRefresh}
                          activeTab={activeTab}
                        />
                      </Row>
                    </form>
                  </CardBody>
                  {ordersData && ordersData?.length ? (
                    <TableContainer
                      columns={
                        activeTab === "1"
                          ? columns1
                          : activeTab === "2"
                          ? columns2
                          : activeTab === "3"
                          ? columns3
                          : columns2
                      }
                      data={searchValue ? searchOrdersData : ordersData || []}
                      isGlobalFilter={true}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      totalPages={totalPages}
                      totalRecords={totalRecords}
                      fetchData={handleFetchData}
                      fetchSortingData={handleFetchSorting}
                      onSearch={handleSearchValueChange}
                      customPageSize={customPageSize}
                      setCustomPageSize={setCustomPageSize}
                      divClass="table-responsive mb-1"
                      tableClass="mb-0 align-middle table-border"
                      theadClass="table-light text-muted table-wrap"
                      SearchPlaceholder="Search for order ID, customer, order status or something..."
                      isOrderFilter={false}
                      manualPagination={true}
                      manualFiltering={true}
                      totalNumberOfRows={totalNumberOfRows}
                      manualSorting={true}
                    />
                  ) : (
                    <div className="py-4 text-center">
                      <div>
                        <i className="ri-search-line display-5 text-success"></i>
                      </div>

                      <div className="mt-4">
                        <h5>{notFound?.loadingContent}</h5>
                      </div>
                    </div>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default EcommerceOrders;
