import { EDIT_SHIPPING_CHARGE, VIEW_SHIPPING_CHARGE } from "./apiRoutes";
import { orderServices } from "./apiServices";

export const editShippingCharge = async (id: number, data: object) => {
  const response = await orderServices.put(
    `${EDIT_SHIPPING_CHARGE}/${id}`,
    data
  );
  return response?.data;
};

export const viewShippingCharge = async (data: object) => {
  const response = await orderServices.post(VIEW_SHIPPING_CHARGE, data);
  return response?.data;
};
